import React, { useState, useRef, useEffect } from 'react';
import { useAuth, ProviderId } from 'features';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@telescope/cassini-ui';
import { useWidget, useTracking } from 'providers';
import { Text, Heading, Display } from 'components';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { AuthButton, Fieldset } from '.';
import { useSession } from 'features/auth';
import { InputName, renderInput } from 'components';
import { OpenSnapshot } from 'types';

type AuthProviders = keyof OpenSnapshot['widget']['auth']['methods']

export const Login = () => {
  const { providers, getProviderById } = useAuth();
  const { login, isLoading, handleSuccess: handleLoginSuccess } = useSession();
  
  const { state: locationState = {} } = useLocation();
  const navigate = useNavigate();

  const { data: auth } = useWidget({ select: (data: OpenSnapshot) =>  data.widget.auth });
  const { methods, login: { content }} = auth!;

  const { trackClickEvent } = useTracking();

  const formMethods = useForm<{ [x: string]: any }>({
    mode: 'onSubmit',
    reValidateMode: 'onChange'
  });
  const { handleSubmit, getValues, trigger, setError, formState: { errors }} = formMethods;
  const [formError, setFormError] = useState<React.ReactNode>(null);
  const hasError = !isEmpty(errors);

  const formErrorRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    formError && formErrorRef.current && formErrorRef.current.scrollIntoView();
  }, [formError])

  const handleSuccess = (data: any, state?: Record<string, any>) => {
    handleLoginSuccess(data);
    navigate(state?.from || '/', { replace: true })
  }

  const handleError = (error?: any, providerId?: AuthProviders) => {
    const method = methods[providerId!] as any;
    const message = method?.content.errorMessages?.[error] ||
                    (content.errorMessage as any)[error] || content.errorMessage.generic;

    setFormError(message);
  }

  const onSubmit = async (providerId: string) => {
    setFormError(null);

    // If no email is entered:
    // Trigger all fields to check for other errors
    // Set email error
    if (providerId === ProviderId.EMAIL && !getValues(InputName.EMAIL)) {
      await trigger();
      return setError(InputName.EMAIL, { type: 'required', message: methods.email.content.input.errorMessage });
    }

    handleSubmit(async (data: any) => {
      const p = getProviderById(providerId);

      if (p.callback) {
        const state = window.btoa(JSON.stringify({ from: locationState?.backgroundLocation }));
        return p.authenticate({ state });
      }
  
      try {
        const userPayload = p.authenticate(data);
        const res = await login(userPayload);

        if (!res) {
          return handleError(content.errorMessage.generic)
        }
  
        handleSuccess({ ...userPayload, ...res })
        navigate(locationState.backgroundLocation, { replace: true });
        
      } catch (error: any) {
        handleError(error)
      } finally {
        trackClickEvent({ name: `${providerId} login button`, section: 'login-modal', url: window.location.href });
      }
    })();
  }
  
  // @TODO ts: fix providers type
  return (
    <Box maxW="400px" mx="auto">

      <Box mb={6} textAlign="center">
        <Heading as="h2" fontWeight="normal" fontSize="2xl" allowMarkdown mb={1}>
          {content.headline}
        </Heading>

        <Text allowMarkdown mb={4}>
          {content.description}
        </Text>
      </Box>

      <FormProvider {...formMethods}>
        <Box as="form"
          noValidate
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}>

          <Fieldset isLoading={isLoading}>   
            {providers.filter((provider: any) => provider.id !== ProviderId.EMAIL).map((provider: any) => {
              const method = methods[provider.id as AuthProviders];
              return (
                <AuthButton providerId={provider.id} onClick={() => onSubmit(provider.id)} key={provider.id}>
                  {method.content.buttons.login}
                </AuthButton>
              )
            })}

            <Display hide={!getProviderById(ProviderId.EMAIL)}>
              <Text fontSize="xs" fontFamily="Login.text" whiteSpace="nowrap" textAlign="center">
                {content.dividerText}
              </Text>

              {renderInput(methods.email.content.input)}
              <AuthButton providerId={ProviderId.EMAIL} onClick={() => onSubmit(ProviderId.EMAIL)}
                mt={3}>
                {methods[ProviderId.EMAIL].content.buttons.login} 
              </AuthButton>
            </Display>

            <Text fontSize="xs" textAlign="center" px={4} fontFamily="Login.legal" color="Login.legal" allowMarkdown>
              {content.legal}
            </Text>

          </Fieldset>
        </Box>

      </FormProvider>
      <Outlet context={{
        handleSuccess,
        handleError
      }}/>
    </Box>
  )
}

