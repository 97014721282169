import { buttonTheme as Button } from './button.theme';
import { containerTheme as Container } from './container.theme';
import { inputTheme as Input } from './input.theme';
import { linkTheme as Link } from './link.theme';
import { checkboxTheme as Checkbox } from './checkbox.theme';
import { formErrorTheme as FormError } from './form-error.theme';
import { modalTheme as Modal } from './modal.theme';
import { formLabelTheme as FormLabel } from './form-label.theme';
import { headingTheme as Heading } from './heading.theme';

import { timeStatTheme as TimeStat } from 'components/time-stat';

export const components = {
  Button,
  Container,
  Link,
  Input,
  Checkbox,
  FormError,
  FormLabel,
  Modal,
  TimeStat,
  Heading
};
