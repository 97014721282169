export const loadFonts = (list: string[]) => {
  if (!list) return;

  list.forEach((url) => {
    const isFontLoaded = document.querySelector(`link[href="${url}"]`);
    if (!url || isFontLoaded) return;

    const fontLink = document.createElement('link');
    document.head.appendChild(fontLink);
    fontLink.rel = 'stylesheet';
    fontLink.href = url;
  });
};