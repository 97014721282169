import { ModalProps } from '@chakra-ui/modal';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, 
  Spinner, AspectRatio, Box, Text, Flex, Heading, VStack, IconButton, HStack } from '@telescope/cassini-ui';
import { Display } from 'components';
import { useVotingGrid } from 'features/vote/providers';
import { useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useParams, Link } from 'react-router-dom';
import _ from 'lodash-es';
import { NomineeT } from '../category';
import { LuChevronLeft, LuChevronRight } from 'react-icons/lu';
import { slugify } from 'features';
import { useTracking } from 'providers';


type VideoPlayerModalProps = Omit<ModalProps, 'children' | 'isOpen'>;

export const VideoPlayerModal = (props: VideoPlayerModalProps) => {
  const { getCategoryBySlug } = useVotingGrid();

  const { categorySlug, nomineeSlug } = useParams();

  const category = useMemo(() => getCategoryBySlug(categorySlug!), [categorySlug]);
  const nomineesBySlugs = useMemo(() => {
    return  _.chain(category?.vote_choices)
    .filter((c: NomineeT) => !!c.videoUrl)
    .keyBy((c: NomineeT) => slugify(c.name))
    .value();
  }, [category?.vote_choices]) as Record<string, NomineeT>;
  const nominee = useMemo(() => nomineeSlug && nomineesBySlugs[nomineeSlug], [nomineesBySlugs, nomineeSlug]);
  
  const slugs = Object.keys(nomineesBySlugs);
  
  const prev = useMemo(() => nomineeSlug && slugs[(slugs.indexOf(nomineeSlug) + slugs.length - 1) % slugs.length], [nomineeSlug]);
  const next = useMemo(() => nomineeSlug && slugs[(slugs.indexOf(nomineeSlug) + 1) % slugs.length], [nomineeSlug])

  const [isLoading, setIsLoading] = useState(true);

  const { trackClickEvent } = useTracking();

  if (!nominee || !nominee.videoUrl) {
    props.onClose();
    return null;
  };

  const trackNavButtonClick = (nextNominee: string, destination: string) => {
    trackClickEvent({
      name: `video_${category.category_title.toLowerCase()}_${nextNominee.toLowerCase()}`, 
      section: categorySlug, 
      destination: destination,
      url: window.location.href
    });            
  }

  return (
    <Modal isOpen={true} isCentered {...props}>
      <ModalOverlay />
      <ModalContent w="full" maxW="810px">
        
        <ModalBody p={0} bg="transparent" color="Modal.text" boxShadow="none">
          <HStack gap={0} w="100%">
            <Display hide={!prev || slugs.length < 2}>
              <NavButton ariaLabel={`go to ${nomineesBySlugs[prev!]?.name}`} 
                href={`/${categorySlug}/${prev}`} icon={<LuChevronLeft size="2em"/>}
                onClick={() => trackNavButtonClick(nomineesBySlugs[prev!]?.name, `/${categorySlug}/${prev}`)} />
            </Display>

            <Box w="full">
              <Flex mb={4} justifyContent="space-between" alignItems="center">
                <Heading fontSize="2xl" fontFamily="VideoPlayer.heading">{category.category_title}</Heading>
                <ModalCloseButton onClick={props.onClose} 
                  position={{base: 'fixed', md: 'relative'}} top={{base: 5, md: 0}} right={{base: 10, md: 0}} />
              </Flex>

              <AspectRatio ratio={16/9} w="full">
                <>
                <Display hide={!isLoading}>
                  <Box><Spinner /></Box>
                </Display>
                <ReactPlayer url={nominee.videoUrl} controls={true} onReady={() => setIsLoading(false)} width="100%" height="100%" />
                </>
              </AspectRatio> 

              <VStack mt={4} spacing={2} align="left" >
                <Text fontSize="lg" fontFamily="VideoPlayer.heading">{nominee.name}</Text>
                <Text fontSize="sm" fontFamily="VideoPlayer.text">{nominee.description}</Text>
              </VStack>
            </Box>


            <Display hide={!next || slugs.length < 2}>
              <NavButton ariaLabel={`go to ${nomineesBySlugs[next!]?.name}`} 
                href={`/${categorySlug}/${next}`} icon={<LuChevronRight size="2em"/>}
                onClick={() => trackNavButtonClick(nomineesBySlugs[next!]?.name, `/${categorySlug}/${next}`)}/>
            </Display>
          </HStack>
        </ModalBody>
          
      </ModalContent>
    </Modal>
  );
};

const NavButton = (
    { ariaLabel, href, icon, onClick } : 
    { ariaLabel: string, href: string, icon: JSX.Element, onClick: () => void }) => {
  return (
    <IconButton as={Link} icon={icon} to={href} 
      aria-label={ariaLabel} bg="Modal.bg" color="Modal.text" 
      _hover={{ opacity: 0.8 }} size={{base: 'xs', sm: 'md', md: 'lg'}} p="0px !important" 
      onClick={onClick}/>
  )
}