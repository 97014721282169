import { v4 as uuidv4 } from 'uuid';
import { ProviderId, ProviderType } from '../../types';

export const Guest = () => {

  const authenticate = (data: Record<string, any>) => (
    { method: 'guest', user_id: uuidv4(), ...data }
  )

  return {
    id: ProviderId.GUEST,
    type: ProviderType.SYNCHRONOUS,
    authenticate
  }
}

