import React, { createContext, useContext, useMemo, useCallback, useState } from 'react';

// @TODO: Update ScrollContext value type
const ScrollContext = createContext<any | undefined>(undefined);

export const ScrollProvider = ({ children } : { children: React.ReactElement }) => {
  const [stickyRef, setStickyRef] = useState<HTMLDivElement | null>(null);

  const scrollTo = useCallback((props: ScrollToOptions, offsetStickyElement: boolean = true) => {
    if (!offsetStickyElement) {
      return window.scrollTo(props);
    }

    const offset = stickyRef?.offsetHeight || 0;
    const top = (props.top || 0) - offset;
    window.scrollTo({ ...props, top });
    
  }, [stickyRef]);

  const scrollToTop = useCallback(() => scrollTo({ top: 0, left: 0 }, false), [scrollTo])

  const scrollToHeader = useCallback(() => {
    stickyRef?.scrollIntoView();
  }, [stickyRef])

  const value = useMemo(() => ({
    scrollTo,
    scrollToTop,
    stickyRef,
    setStickyRef,
    scrollToHeader
  }), [
    scrollTo,
    scrollToTop,
    stickyRef,
    setStickyRef,
    scrollToHeader
  ])

  return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export function useScroll() {
  const context = useContext(ScrollContext);

  if (context === undefined) {
    throw new Error('useSscroll must be used within a StorageContext');
  }

  return context;
}
