import { SocialColorScheme } from 'types/app';
import { Theme } from '../../types';

export const cmtEvergreenFonts = [
  'https://content.votenow.tv/app/projects/mtv/cmt/fonts/stylesheet.css'
];

const FONTS = {
  TITLING_GOTHIC_COMP: 'Titling Gothic Comp, sans-serif',
  TITLING_GOTHIC_NARROW_REGULAR: 'Titling Gothic Narrow Regular, sans-serif',
  TITLING_GOTHIC_NARROW_STANDARD: 'Titling Gothic Narrow Standard, sans-serif',
}

export const cmtEvergreenCategoryBg = {
  bgImage: 'none',
};

export const cmtEvergreenSocialColorScheme: SocialColorScheme = SocialColorScheme.BRAND;

export const cmtEvergreenTheme: Theme = {
  colors: {
    brand1: '#FB156B',
    brand2: '#F3FB15',
    brand3: '#0A283D',
    // text
    text1: '#F8F8F8',
    text2: '#808284',
    text3: '#373A3D',
    text4: '#808284',
    // ui
    ui2: '#F8F8F8',
    ui4: '#05121C',
    ui5: '#0A283D',
    ui6: '#071D2C',
    // support
    support2: '#F3FB15',
    support3: '#005377',
    support4: '#E0182D'
  },
  fonts: {
    heading: FONTS.TITLING_GOTHIC_COMP,
    body: FONTS.TITLING_GOTHIC_NARROW_STANDARD
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
        '::selection': {
          bg: 'color-mix(in srgb, var(--cassini-colors-brand1), transparent 20%)'
        }
      },
      body: {
        color: 'text3',
        bg: 'ui2',
        lineHeight: 'shorter',
      },
    },
  },
  semanticTokens: {
    colors: {
      'chakra-placeholder-color': 'text1',
      '--modal-bg': 'transparent',
      Button: {
        primary: {
          default: {
            bg: 'brand1',
            text: 'text1'
          },
          hover: {
            bg: 'color-mix(in srgb, var(--cassini-colors-brand1), transparent 20%)',
            text: 'text1'
          }
        },
        secondary: {
          default: {
            bg: 'ui4',
            text: 'text3'
          },
          hover: {
            bg: 'color-mix(in srgb, var(--cassini-colors-ui4), transparent 10%)',
            text: 'text3'
          }
        }
      },
      Link: {
        text: 'text3'
      },
      Header: {
        bg: '#000'
      },
      HeroSection: {
        textScheme: {
          bg: 'ui4',
          text: 'text1'
        },
        imageScheme: {
          text: 'text1'
        }
      },
      CategoryButton: {
        bg: 'color2',
        heading: 'text3',
        text: 'text2',
      },
      CategoryGrid: {
        bg: 'transparent',
        text: 'text3'
      },
      NomineeMedia: {
        text: 'text1',
        detail: 'support2',
        winner: {
          bg: 'brand1',
          text: 'text1'
        }
      },
      MultiVoteCard: {
        bg: 'ui2',
        text: 'text3',
        button: {
          default: {
            bg: 'ui4',
            icon: 'text1',
          },
          hover: {
            bg: '#05121C80',
            icon: 'text1',
          },
          disabled: {
            bg: '#05121C20',
            icon: 'text1',
          }
        }
      },
      SingleVoteCard: {
        button: {
          default: {
            bg: 'Button.primary.default.bg',
            text: 'Button.primary.default.text',
            loading: 'Button.primary.default.text'
          },
          hover: {
            bg: 'Button.primary.hover.bg',
            text: 'Button.primary.hover.text',
          }
        }
      },
      ProgressBar: {
        bg: 'ui4',
        text: 'text1'
      },
      TurboBanner: {
        bg: 'support3',
        text: 'text1'
      },
      Countdown: {
        label: {
          bg: 'brand1',
          color: 'text1'
        },
        text: 'text1'
      },
      InfoContainer: {
        bg: 'ui4'
      },
      Sponsor: {
        text: {
          light: 'text4',
          dark: 'ui6'
        }
      },
      UpsellBanner: {
        bg: 'support3',
        text: 'text1'
      },
      UpsellModal: {
        bg: 'ui5',
        text: 'text1'
      },
      Footer: {
        bg: 'ui4',
        link: {
          default: 'text2',
          hover: 'brand2'
        },
        text: 'text2'
      },
      Form: {
        error: 'support4',
        input: {
          bg: 'ui6',
          text: 'text1',
        },
        google: {
          default: {
            bg: '#5285EC',
            text: 'text1'
          },
          hover: {
            bg: '#5285ECDD',
            text: 'text1'
          }
        }
      },
      Modal: {
        overlay: 'rgba(0, 0, 0, 0.80)',
        bg: 'ui5',
        text: 'text1',
        icons: 'text1'
      },
      Login: {
        legal: 'text2'
      },
      Share: {
        email: {
          icon: 'Button.primary.default.text',
          bg: 'ui6',
          hover: 'ui6'
        }
      },
      SocialButton: {
        icon: '#fff',
        text: '#fff',
        bg: 'brand2'
      }
    },
    fonts: {
      Button: {
        text: FONTS.TITLING_GOTHIC_NARROW_REGULAR
      },
      CategoryButton: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      NomineeMedia: {
        winner: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      MultiVoteCard: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        count: FONTS.TITLING_GOTHIC_COMP
      },
      UpsellBanner: {
        text: FONTS.TITLING_GOTHIC_NARROW_REGULAR,
        button: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      UpsellModal: {
        text: FONTS.TITLING_GOTHIC_NARROW_REGULAR,
      },
      Header: {
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      Footer: {
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Login: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        legal: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Form: {
        input: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      VideoPlayer: {
        heading: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Countdown: {
        number: FONTS.TITLING_GOTHIC_COMP,
        label: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      }
    },
    letterSpacings: {
      CategoryButton: {
        heading: '3px'
      },
    }
  }
}
