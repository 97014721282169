import { StyleFunctionProps } from '@telescope/cassini-ui';

export const formErrorTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    return {
      text: {
        color: 'Form.error'
      }
    }
  }
};