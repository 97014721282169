import { Flex, Box, VStack } from '@telescope/cassini-ui';
import { Heading, Text } from 'components';
import { useCallback, useEffect, useRef } from 'react';
import { ACCORDION_MOTION_SETTINGS } from 'features/vote/utils/constants';
import { useScroll, useWidget } from 'providers';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { useVotingGrid, replaceWildcardsWithProgress } from 'features/vote';
import { useParams } from 'react-router-dom';
import { MultiVoteSnapshot } from 'types';

type CategoryButtonProps = { 
  isExpanded?: boolean, 
  categorySlug: string
}

export const CategoryButton = ({ isExpanded, categorySlug } : CategoryButtonProps) => {
  const { data: votingData } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.vote })
  const { content } = votingData!;

  const { getCategoryBySlug, getCategoryTotal, voteLimit, expandedCategoryIndexes, isCategorySubmitted } = useVotingGrid();
  const category = getCategoryBySlug(categorySlug);

  const { categorySlug: categoryPathname } = useParams();

  const buttonRef = useRef<HTMLDivElement>(null);
  const { scrollTo, stickyRef } = useScroll();

  const scrollCategoryToTop = () => {
    const top = buttonRef.current?.getBoundingClientRect()?.top || 0;
    const { scrollY } = window;
    const position = top + scrollY;
    scrollTo({ top: position, behavior: 'smooth' });
  }

  const renderSubheadline = useCallback(() => {
    const isSubmitted = isCategorySubmitted(category.category_key);
    return isSubmitted? content.progress?.submitted :
      replaceWildcardsWithProgress(getCategoryTotal(category.category_key), voteLimit, category.subheadline)
  }, [isCategorySubmitted, getCategoryTotal, category.category_key, voteLimit, category.subheadline, content.progress?.submitted])

  useEffect(() => {
    if (categoryPathname !== categorySlug) return;

    // Wait for accordion to finish animating and scroll category to top
    setTimeout(() => {
      scrollCategoryToTop();
    }, ACCORDION_MOTION_SETTINGS.DURATION * 1000)
    
  }, [categoryPathname, categorySlug, stickyRef, expandedCategoryIndexes])

  return (
    <Flex ref={buttonRef} opacity={isExpanded? 1 : .6} _hover={{opacity: 1, shadow: '0px 8px 22px -6px rgba(0, 0, 0, 0.30)'}}
      bg="CategoryButton.bg" w="100%" maxW="100%" justify="center" align="center" py={8}>
      <VStack spacing={3} px={{base: 16, md: 20}}>
          <Heading as="h2" fontSize={{base: '2xl', sm: '3xl'}} position="relative" color="CategoryButton.heading" 
            letterSpacing="CategoryButton.heading" textTransform="uppercase">
            {category.category_title}
            <AccordionIcon isExpanded={isExpanded} />
          </Heading>
          <Text fontSize="sm" fontWeight="800" color="CategoryButton.text">
            {renderSubheadline()}
          </Text>
      </VStack>
    </Flex>
  )
}

const AccordionIcon = ({ isExpanded = false } : { isExpanded?: boolean }) => {
  return (
    <Box position="absolute" fontSize={30} top="50%" right={{base: '-12px', md: '-24px'}} transform="translate(100%, -50%)">
      { isExpanded? <BsChevronUp /> : <BsChevronDown /> }
    </Box>
  ) 
}