import { StyleFunctionProps } from '@telescope/cassini-ui';

export const modalTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    return {
      body: { p: [7, 0] },
      closeButton: {
        color: 'Modal.icons',
        svg: {
          width: '22px',
          height: '22px'
        }
      },
      dialog: {
        bg: 'transparent',
        boxShadow: 'none'
      },
      overlay: {
        bg: 'Modal.overlay',
      }
    };
  },
};
