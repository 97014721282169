import { Box, HStack, Flex, Fade } from '@telescope/cassini-ui';
import { Link } from 'components';
import { useMtvData } from 'hooks';
import { LogoLg, LogoSm } from './logos';
import { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { HeaderLink } from './HeaderLink';
import { useScroll } from 'providers';

export const DesktopHeader = () => {
  const { ref, inView, entry } = useInView({
    threshold: .4
  });
  const { setStickyRef } = useScroll();

  const displayInlineLogo = useMemo(() => (entry?.intersectionRatio || 1) < .4 || !inView, [entry?.intersectionRatio])

  const data = useMtvData('MTVNavigation', 'MTVNavigationLoaded');
  if (!data) return null;

  return (
    <>
      <Flex as="header" bg="Header.bg" w="full" pt={16} pb={10} justifyContent="center" alignItems="center" ref={ref}>
        <Link href={window.mtvHeaderUrl}><LogoLg /></Link>
      </Flex>

      <HStack as="nav" bg="Header.bg" justifyContent="center" ref={setStickyRef} spacing={6} 
              position="sticky" zIndex="sticky" top={0} w="100%">
        <Box as={Fade} in={displayInlineLogo} position="absolute" left={10}><LogoSm /></Box>

        {data.navigationEntries?.map((link: { title: string, url: string, id: string }) => 
          <HeaderLink href={link.url} copy={link.title} key={link.id} />)}
    </HStack>    
  </>
  )
}