import { SystemStyleObject } from '@telescope/cassini-ui';

export const linkTheme = {
  baseStyle: ({ colorScheme }: SystemStyleObject) => ({
    color: `${colorScheme}.500`,
    _hover: { color: `${colorScheme}.300`, textDecoration: 'none' }
  }),
  variants: {
    /**
     * emphasized link is bold and has no underline
     */
    emphasized: ({ colorScheme }: SystemStyleObject) => ({
      color: `${colorScheme}.500`,
      _hover: { color: `${colorScheme}.300`, textDecoration: 'none' },
      _active: { color: `${colorScheme}.700`, textDecoration: 'none' },
      textDecoration: 'none',
      fontWeight: 'bold',
    }),
  },
};
