import { ThemeConfig, extendTheme } from '@telescope/cassini-ui';
import { breakpoints } from 'theme/foundations';
import { components } from 'theme/components';

import { 
  cmtBrandedTheme as brandTheme,  
  cmtBrandedFonts as fonts,
  cmtBrandedCategoryBg as categoryBg,
  cmtBrandedSocialColorScheme as socialColorScheme
} from './brands';

export {fonts}
export {categoryBg}
export {socialColorScheme}

export const customTheme = (): ThemeConfig => {
  return extendTheme({
    config: {
      initialColorMode: 'light',
      useSystemColorMode: false,
      cssVarPrefix: 'cassini',
    },
    components,
    sizes: {
      container: {
        appMax: '1230px',
      },
    },
    breakpoints: breakpoints,
    ...brandTheme
  });
};