import { createContext, useContext, useReducer } from 'react';
import { randomId } from 'utils';
import { ContentModal } from '../components/ContentModal';
import { modalsReducer } from '../reducers/modalsReducer';
import { ModalSettings, ModalState } from '../types/modals';

export interface ModalsContextProps {
  modals: ModalState[];
  openModal: (props: ModalSettings) => string;
  closeModal: (id: string, canceled?: boolean) => void;
  closeAll: () => void;
}

// ModalsContext
export const ModalsContext = createContext<ModalsContextProps>(undefined!);
ModalsContext.displayName = 'CassiniModalsContext';

// ModalsProvider
export interface ModalsProviderProps {
  /** App children */
  children: React.ReactNode;

  /** Shared Modal component props, applied for every modal */
  modalProps?: ModalSettings;
}

export function ModalsProvider({ children, modalProps }: ModalsProviderProps) {
  const [state, dispatch] = useReducer(modalsReducer, { modals: [], current: null });

  const closeAll = (canceled?: boolean) => {
    state.modals.forEach((modal) => {
      modal.props?.onClose?.();
    });
    dispatch({ type: 'CLOSE_ALL' });
  };

  const openModal = ({ id = randomId(), ...props }: ModalSettings) => {
    dispatch({
      type: 'OPEN',
      payload: {
        id,
        type: 'content',
        props,
      },
    });
    return id;
  };

  const closeModal = (id: string, canceled?: boolean) => {
    if (state.modals.length <= 1) {
      closeAll(canceled);
      return;
    }

    const modal = state.modals.find((item) => item.id === id);

    if (!modal) return;

    modal?.props?.onClose?.();
    dispatch({ type: 'CLOSE', payload: modal.id });
  };

  const ctx: ModalsContextProps = {
    modals: state.modals,
    openModal,
    closeModal,
    closeAll,
  };

  const getCurrentModal = () => {
    // Currently only 'content' modal exists, but this can be added to to support confirm dialogs
    switch (state.current?.type) {
      case 'content': {
        const { children: currentModalChildren, ...rest } = state.current.props;

        return {
          modalProps: rest,
          content: <>{currentModalChildren}</>,
        };
      }
      default: {
        return {
          modalProps: {},
          content: null,
        };
      }
    }
  };

  const { modalProps: currentModalProps, content } = getCurrentModal();

  return (
    <ModalsContext.Provider value={ctx}>
      <ContentModal
        {...modalProps}
        {...currentModalProps}
        isOpen={state.modals.length > 0}
        onClose={closeAll}>
        {content}
      </ContentModal>
      {children}
    </ModalsContext.Provider>
  );
}

export function useModals() {
  const ctx = useContext(ModalsContext);

  if (!ctx) {
    throw new Error(
      '[@telescope/cassini-modals] useModals hook was called outside of context, wrap your app with ModalsProvider component'
    );
  }

  return ctx;
}
