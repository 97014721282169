import { useCallback } from 'react';
import axios from 'axios';
import { ProviderId, ProviderType } from '../../types';
import { getFromQsp } from '../../utils';

export const GOOGLE_OAUTH_VERSION = 'v2';

export const GoogleAuthError = {
  GENERIC: 'generic'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type GoogleAuthError = typeof GoogleAuthError[keyof typeof GoogleAuthError];

type GoogleProviderProps = {
  clientId: string 
  scope?: string
  responseType?: string 
  version?: string
}

export const Google = ({ 
  clientId, 
  scope = 'https://www.googleapis.com/auth/userinfo.email', 
  responseType = 'token',
  version = GOOGLE_OAUTH_VERSION
}: GoogleProviderProps) => {

  const authenticate = ({ redirectUri = `${window.location.href}/callback/google`, state } : { redirectUri: string, state: string }) => {
    const query = new URLSearchParams({
      client_id: clientId,
      redirect_uri: redirectUri,
      response_type: responseType,
      scope,
      include_granted_scopes: 'true',
      state
    });

    window.location.href = `https://accounts.google.com/o/oauth2/${version}/auth?${query}`
  }

  const callback = async () => {
    const accessToken = getFromQsp('access_token');

    if (!accessToken) {
      throw Error(GoogleAuthError.GENERIC);
    };

    try {
      const user = await getUserData(accessToken);
      let state = getFromQsp('state');
      state = state && JSON.parse(window.atob(state));
  
      return { payload: user, state };
    } catch (error: any) {
      throw Error(error.message || GoogleAuthError.GENERIC);
    }
  }

  const getUserData = useCallback(
    async (accessToken: string) => {
      const { data: user } = await axios.get('https://www.googleapis.com/oauth2/v2/userinfo?fields=id,email,name', {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
       })

      return {
        user_id: user.id,
        user_email: user.email,
        display_name: user.name,
        method: 'google'
      }
    },
    [scope]
  );

  return {
    id: ProviderId.GOOGLE,
    type: ProviderType.OAUTH,
    authenticate,
    callback
  }
}