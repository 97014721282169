import { useCallback } from 'react';
import axios from 'axios';
import { ProviderId, ProviderType } from '../../types';
import { getFromQsp } from '../../utils';

export const FACEBOOK_OAUTH_VERSION = 'v17.0';

export const FacebookAuthError = {
  PERMISSIONS: 'fbPermissions',
  GENERIC: 'fbGeneric'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type FacebookAuthError = typeof FacebookAuthError[keyof typeof FacebookAuthError];

type FacebookProviderProps = {
  appId: string 
  scope?: string
  responseType?: string 
  authType?: 'rerequest' | 'reauthenticate' | 'reauthorize',
  version?: string
}

export const Facebook = ({ 
  appId, 
  scope = '', 
  responseType = 'token granted_scopes', 
  authType = 'rerequest',
  version = FACEBOOK_OAUTH_VERSION
}: FacebookProviderProps) => {

  const authenticate = ({ redirectUri = `${window.location.href}/callback/facebook`, state } : { redirectUri: string, state: string }) => {
    const query = new URLSearchParams({
      client_id: appId,
      response_type: responseType,
      auth_type: authType,
      scope,
      redirect_uri: redirectUri,
      state
    });

    window.location.href = `https://www.facebook.com/${version}/dialog/oauth?${query}`;
  }

  const callback = async () => {
    const accessToken = getFromQsp('access_token');
    if (!accessToken) {
      throw Error(FacebookAuthError.GENERIC);
    };

    try {
      const user = await getUserData(accessToken);

      if (scope.includes('email') && !user.user_email) {
        throw Error(FacebookAuthError.PERMISSIONS);
      }
  
      let state = getFromQsp('state');
      state = state && JSON.parse(window.atob(state));
  
      return { payload: user, state };
    } catch (error: any) {
      throw Error(error.message || FacebookAuthError.GENERIC);
    }
  }

  const getUserData = useCallback(
    async (accessToken: string) => {
      const fields = ['first_name', 'last_name', 'name', 'id', 'email', 'permissions'].join(',');

      const res = await axios.get(
        `https://graph.facebook.com/${version}/me?fields=${fields}&access_token=${accessToken}`,
      );

      return {
        user_email: res.data.email,
        first_name: res.data.first_name,
        last_name: res.data.last_name,
        user_id: res.data.id,
        method: 'fb'
      }
    },
    [version, scope],
  );

  return {
    id: ProviderId.FACEBOOK,
    type: ProviderType.OAUTH,
    authenticate,
    callback
  }
}