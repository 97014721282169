import { useEffect, useState } from 'react';

export const useMtvData = (property: string, callbackName: string) => {
  const [ data, setData ] = useState<Record<string, any> | null>(null);

  useEffect(() => {
    if ((window as any)[property]) {
      return setData((window as any)[property])
    }

    (window as any)[callbackName] = (mtvData: Record<string, any>) => {
      setData(mtvData)
    }
  }, [])

  return data;
}