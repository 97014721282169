import { Route, Routes, Navigate } from 'react-router-dom';
import { ClosedSnapshot } from 'components';
import { Auth } from 'features';

export const ClosedRoutes = () => {
  return (
    <Auth>
      <Routes>
        <Route index={true} element={<ClosedSnapshot />} />
        <Route path="/:categorySlug/:nomineeSlug?" element={<ClosedSnapshot />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </Auth>
  )
}