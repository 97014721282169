import { Route, Routes } from 'react-router-dom';
import { AppShell } from 'components';
import { useWidget } from 'providers';
import { Campaign } from 'types';
import { SnapshotType } from 'types';
import { OpenRoutes } from './OpenRoutes';
import { ClosedRoutes } from './ClosedRoutes';

// Returns routes based on the currently live snapshot type
const SnapshotRoutes = ({ type }: { type: SnapshotType }) => {
  switch (type) {
    case SnapshotType.MULTI_VOTE:
    case SnapshotType.SINGLE_VOTE:
      return <OpenRoutes />
    case SnapshotType.CLOSED:
      return <ClosedRoutes />
      default:
      return null;
  }
};

export const AppRoutes = () => {
  const { data } = useWidget({ select: (data: Campaign) => data.snapshot });
  const snapshot = data!;

  return (
    <>
      <Routes>
        <Route path="/" element={<AppShell />}>
          <Route index={true} element={<SnapshotRoutes type={snapshot.type}/>}/>
          <Route path="*" element={<SnapshotRoutes type={snapshot.type}/>}/>
        </Route>
      </Routes>
    </>
  )
}
