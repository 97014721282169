
import React, { createContext, useCallback, useContext, useMemo, useReducer, useEffect, useState } from 'react';
import { useVote, VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { useWidget } from 'providers';
import { VoteReducer, initialState, VoteAction, CategoryState } from '../reducers';
import { useSession } from 'features/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { ACTION_TYPES } from 'utils';
import { Campaign, CategoryT, OpenSnapshot, SnapshotType } from 'types';
import { slugify, useTurboVote } from 'features';
import { useTracking } from 'providers';
import { getAppConfig } from 'utils';
import { isEmpty } from 'lodash-es';

type VotingContextValue<Snapshot extends Campaign> = { 
  categories: Record<string, CategoryT<Snapshot>>
  votableCategories: CategoryT<OpenSnapshot>[],
  submittedCategories: CategoryT<OpenSnapshot>[],
  getCategoryBySlug: (id: string) => CategoryT<Snapshot>
  handleAddVotes: (categoryId: string, nomineeId: string) => void,
  handleSubtractVotes: (categoryId: string, nomineeId: string) => void,
  getCategoryTotal: (categoryId: string) => number,
  getNomineeVotes: (categoryId: string, nomineeId: string) => number
  openSingleCategory: (slug?: string) => void
  onCategoryChange: (expandedIndex: number[]) => void
  isCategorySubmitted: (categoryId: string) => boolean,
  updateVoteHistory: (voteHistory: Record<string, any>) => void
  handleSingleSubmit: (categoryId: string, nomineeId: string) => Promise<void>
  handleBatchSubmit: (categoryId: string) => Promise<any>
  getCategoryPosition: (categorySlug: string) => number
  handleLogout: () => void,
  getNextVotableCategorySlug: (categoryId: string, allocatedVotes?: CategoryState) => string | undefined,
  voteLimit: number,
  expandedCategoryIndexes: number[],
  setPendingVote: ({ categoryId, nomineeId } : {categoryId: string, nomineeId: string}) => void
}

type VotingContextProps = {
  children: React.ReactNode
};

const VotingGridContext = createContext<VotingContextValue<any>>(undefined as any);

const { isDevMode } = getAppConfig();

export function VoteGridProvider<Snapshot extends Campaign>({ children } : VotingContextProps) {
  const { data: voteSettings } = useWidget({
    select: (data: OpenSnapshot) => data.snapshot.snapshot_views.vote.settings
  });
  const { data: snapshotId } = useWidget({
    select: (data: OpenSnapshot) => data.sid
  });
  const { data: snapshotType } = useWidget({
    select: (data: Campaign) => data.snapshot.type
  });

  const { isAuthenticated, validateSession, session, logout } = useSession();
  const navigate = useNavigate();

  const { categorySlug } = useParams();
  const [ expandedCategoryIndexes, setExpandedCategoryIndexes ] = useState<number[]>([]);
  const [allocatedVotes, dispatch] = useReducer(VoteReducer, initialState);
  const { data: votingData } = useWidget({ select: (data: Campaign) => data.snapshot.snapshot_views.vote })

  const { trackPageView, setPageName } = useTracking();

  const { isTurbo } = useTurboVote();
  const vote = useVote();

  const [pendingVote, setPendingVote] = useState<{categoryId: string, nomineeId: string} | null>(null);

  useEffect(function handleAuthSuccess() {
    if (isEmpty(categories) || !votableCategories.length) return;

    if (!isAuthenticated) {
      openSingleCategory(slugify(votableCategories[0]?.category_title));
      return;
    }

    const handleUserVoteHistory = async () => {
      const voteHistory = await getVoteHistory();
      const allocated = updateVoteHistory(voteHistory || {}, pendingVote);

      if (pendingVote && !voteHistory?.[pendingVote?.categoryId]) {
        return;
      }

      const lastCategory = Object.values(categories).slice(-1)[0].category_key;
      const slug = getNextVotableCategorySlug(lastCategory, allocated);
      openSingleCategory(slug);
    };

    if (snapshotType === SnapshotType.SINGLE_VOTE && pendingVote) {
      handleSingleSubmit(pendingVote.categoryId, pendingVote.nomineeId);
    } else {
      handleUserVoteHistory();
    }

    setPendingVote(null);
    
  }, [isAuthenticated, snapshotId])

  useEffect(function updatePageName() {
    setPageName(categorySlug)
  }, [categorySlug]);
  
  useEffect(function trackPageChange() {
    if (!categorySlug) return;
    const category = getCategoryBySlug(categorySlug);
    if (!category) return;
    trackPageView({ title: category.category_title, name: categorySlug });
  }, [categorySlug])

  /**
   * Categories
   */
  const categories = useMemo(() => {
    const categoryArray = votingData!.categories as unknown as CategoryT<Snapshot>[];
    return categoryArray?.reduce((acc: Record<string, CategoryT<Snapshot>>, cat: any) => {
      acc[slugify(cat.vote_category.category_title)] = cat.vote_category;
      return acc;
    }, {});
  }, [votingData]) as Record<string, CategoryT<Snapshot>>;


  const handleLogout = useCallback(() => {
    // Log out of session
    logout();
    // Clear votes
    dispatch({type: VoteAction.RESET})
    // Navigate to first category
    const firstCategorySlug = Object.keys(categories)[0];
    openSingleCategory(firstCategorySlug);
  }, [categories]);

  const getCategoryBySlug = useCallback((slug: string) => {
    return categories?.[slug]
  }, [categories]);

  const votableCategories = useMemo(() => Object.values(categories)
    .filter((c: CategoryT<OpenSnapshot>) => (c.isVotable )) as 
      CategoryT<OpenSnapshot>[], [categories, allocatedVotes]) || [];

  const submittedCategories = useMemo(() => Object.values(categories)
    .filter((c: CategoryT<OpenSnapshot>) => (
      !!allocatedVotes[c.category_key]?.isSubmitted
    )) as CategoryT<OpenSnapshot>[], [categories, allocatedVotes]) || [];

  /**
   * Category Votes
   */  
  const voteLimit = useMemo(() => {
    if (!voteSettings) return 0;
    return isTurbo && "turbo" in voteSettings.voteLimit? 
      voteSettings.voteLimit.turbo: voteSettings.voteLimit.default
  },[isTurbo]) || 0;

  const handleAddVotes = useCallback((categoryId: string, nomineeId: string) => {
    if (!validateSession()) {
      setPendingVote({categoryId, nomineeId});
      return;
    }

    dispatch({
      type: VoteAction.ADD_VOTE, 
      payload: { categoryId, nomineeId }
    });
  }, [isAuthenticated, validateSession])

  const handleSubtractVotes = useCallback((categoryId: string, nomineeId: string) => {
    if (!validateSession()) return;

    dispatch({
      type: VoteAction.SUBTRACT_VOTE, 
      payload: { categoryId, nomineeId }
    });
  }, [isAuthenticated, validateSession])

  const getCategoryTotal = useCallback((categoryId: string) => {
    return allocatedVotes[categoryId]?.total || 0;
  }, [allocatedVotes, isAuthenticated])

  const getNomineeVotes = useCallback((categoryId: string, nomineeId: string) => {
    return allocatedVotes[categoryId]?.allocated[nomineeId] || 0
  }, [allocatedVotes])

  const getVoteHistory = async () => {
    if (!session?.user.user_id) return null;

    const { votestring } = await vote({
      action_type: ACTION_TYPES.GET,
      user_id: session?.user.user_id,
      method: session?.user.method
    })

    return (!votestring || !JSON.parse(votestring))? null : 
      JSON.parse(votestring);
  }

  const isCategorySubmitted = useCallback((categoryId: string) => {
    return !!allocatedVotes[categoryId]?.isSubmitted
  }, [allocatedVotes])

  const handleBatchSubmit = useCallback(async (categoryId: string) => {
    if (isDevMode) return;

    const res = await vote({
      action_type: ACTION_TYPES.VOTE,
      user_id: session?.user.user_id,
      method: session?.user.method,
      category: categoryId,
      total: allocatedVotes[categoryId].total,
      ...allocatedVotes[categoryId].allocated
    })

    if (!res || res.response_code !== VoteAPIResponseCode.VALID) {
      throw new Error(res?.response_code || VoteAPIResponseCode.GENERAL_INVALID)
    }

    updateVoteHistory(JSON.parse(res.votestring!))
  }, [allocatedVotes, session])

  const handleSingleSubmit = async (categoryId: string, nomineeId: string) => {
    if (isDevMode) return;
    
    const res = await vote({
      action_type: ACTION_TYPES.VOTE,
      user_id: session?.user.user_id,
      method: session?.user.method,
      category: categoryId,
      contestant: nomineeId
    })

    if (!res || res.response_code !== VoteAPIResponseCode.VALID) {
      throw new Error(res?.response_code || VoteAPIResponseCode.GENERAL_INVALID)
    }

    updateVoteHistory(JSON.parse(res.votestring!))
  }

  const updateVoteHistory = (
    voteHistory: Record<string, any>, 
    pendingVote?: {categoryId: string, nomineeId: string} | null): CategoryState => {

    const previousVotes = Object.entries(voteHistory).reduce((acc, [key, value]) => {
      const { total, ...votes } = value as { total: number, [x:string]: number };
      acc[key] = {
        total,
        isSubmitted: true,
        allocated: {...votes}
      }
      return acc;
    }, {} as CategoryState)

    if (pendingVote && !previousVotes[pendingVote.categoryId]) {
      previousVotes[pendingVote.categoryId] = {
        total: 1,
        allocated: { [pendingVote.nomineeId]: 1 }
      }
    }

    voteHistory && dispatch({
      type: VoteAction.SET_VOTES, 
      payload: previousVotes
    });
    return previousVotes;
  }

  /**
   * Category Navigation
   */
  const getNextVotableCategorySlug = useCallback((categoryId: string, allocated = allocatedVotes) => {
    const length = votableCategories.length;
    const activeCategoryIndex = votableCategories.findIndex((c) => c.category_key === categoryId);

    for (let i = 0; i < length; i++) {
      const index = ((activeCategoryIndex + i) + 1) % length;
      const nextCategory = votableCategories[index];
      if (nextCategory.isVotable && !allocated[nextCategory.category_key]?.isSubmitted) {
       return slugify(nextCategory.category_title);
      }
    }
  }, [votableCategories, categories, allocatedVotes]);

  /*
  * Opens a category and retracts all others
  */
  const openSingleCategory = (slug?: string) => {
    if (!slug) return;
    navigate(`/${slug}`);
    const nextIndex = Object.keys(categories).indexOf(slug);
    setExpandedCategoryIndexes([nextIndex]);
  }

  useEffect(() => {
    if (!categorySlug) return;

    const catIndex = Object.keys(categories).indexOf(categorySlug);
    setExpandedCategoryIndexes([catIndex]);
  }, [categories]); 

  const onCategoryChange = useCallback((expandedIndex: number[]) => {
    setExpandedCategoryIndexes(expandedIndex);
  }, [setExpandedCategoryIndexes])

  const getCategoryPosition = useCallback((categorySlug: string) => {
    return Object.keys(categories).indexOf(categorySlug) + 1;
  }, [categories])

  // 
  const value = useMemo(() => ({
    categories,
    votableCategories,
    submittedCategories,
    getCategoryBySlug,
    handleAddVotes,
    handleSubtractVotes,
    getCategoryTotal,
    getNomineeVotes,
    openSingleCategory,
    onCategoryChange,
    isCategorySubmitted,
    updateVoteHistory,
    handleSingleSubmit,
    handleBatchSubmit,
    getCategoryPosition,
    handleLogout,
    getNextVotableCategorySlug,
    voteLimit,
    expandedCategoryIndexes,
    setPendingVote
  }), [
    categories,
    votableCategories,
    submittedCategories,
    getCategoryBySlug,
    handleAddVotes,
    handleSubtractVotes,
    getCategoryTotal,
    getNomineeVotes,
    openSingleCategory,
    onCategoryChange,
    isCategorySubmitted,
    updateVoteHistory,
    handleSingleSubmit,
    handleBatchSubmit,
    getCategoryPosition,
    handleLogout,
    getNextVotableCategorySlug,
    voteLimit,
    expandedCategoryIndexes,
    setPendingVote
  ])

  return <VotingGridContext.Provider value={value}>{ children }</VotingGridContext.Provider>
}

export function useVotingGrid<Snapshot extends Campaign>() {
  const context = useContext<VotingContextValue<Snapshot>>(VotingGridContext);

  if (!context) {
    throw new Error('useVotingGrid must be used within a VotingGridProvider')
  }

  return context;
}