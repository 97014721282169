import { useGeo } from '@telescope/cassini-hooks';
import { useWidget } from 'providers';
import { Container, Spinner } from '@telescope/cassini-ui';
import React, { useEffect } from 'react';
import { ErrorPage } from 'features';
import axios from 'axios';

type GeoFilterClusivity = 'inclusive' | 'exclusive';

interface GeoBlockingProps {
  children: React.ReactNode;
}

const geoInterceptor = axios.interceptors.request.use((config) => {
  if (config.url?.includes('/api/getregion.json')) {
    config.url = `${window.tsReactInitConfig.basename}${config.url}`.replace(/\/{2,}/g, '/');
    axios.interceptors.request.eject(geoInterceptor);
  }
  return config;
});

export const GeoBlocking = ({ children }: GeoBlockingProps) => {
  const {
    data: { settings: widgetSettings },
  } = useWidget({ select: (data: any) => data.widget });
  const {
    general: { geo },
  } = widgetSettings;

  const { inRegion, isLoading, aprAllowed } = useGeo(geo.countries, {
    listType: geo.countries_list_type,
    allowAprUsers: geo.allow_apr,
  });
  
  if (isLoading) {
    return (
      <Container centerContent>
        <Spinner label="Loading..." />
      </Container>
    );
  }

  if (!inRegion) {
    return <ErrorPage errorType='geo'/>
  }

  if (!aprAllowed) {
    return <ErrorPage errorType='apr'/>
  }

  return <>{children}</>;
};
