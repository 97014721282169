import React from 'react';
import { Container, VStack } from '@telescope/cassini-ui';

export const InfoContainer = ({ children } : { children: React.ReactNode }) => {
  return (
    <Container as={VStack} py={{base: 8, md: 10}} textAlign="center" 
      bg="InfoContainer.bg" _empty={{display: 'none'}} gap={4}>
      { children }
    </Container>
  )
}