import { Button, Link } from '@telescope/cassini-ui';
import { FaXTwitter } from 'react-icons/fa6';
import { SocialButtonProps } from './SocialButton';
import { socialColorScheme } from 'theme';
import { SocialColorScheme } from 'types';

export const TwitterButton = ({ copy, ...props } : SocialButtonProps) => {
  const isMonochrome = socialColorScheme === SocialColorScheme.MONOCHROME;
  return (
    <Button as={Link} target="_blank"
            leftIcon={<FaXTwitter size={28} color={isMonochrome? 'var(--cassini-colors-SocialButton-icon)' : 'currentColor'}/>}
            fontSize="sm" py={6}
            {...(isMonochrome? {
                bg: 'SocialButton.bg', color: 'SocialButton.text'
              } : {
                bg: '#1DA1F2', color: 'white'
            })}
            _hover={{textDecoration: 'none'}} _active={{}}
            {...props}>
      {copy}
    </Button>
  )
}