import { Route, Routes } from 'react-router-dom';
import { OpenSnapshot } from 'components';
import { AuthRoutes, Auth } from 'features';
import { useLocation } from 'react-router-dom';

export const OpenRoutes = () => {
  const location = useLocation();
  const state = location.state as {
    backgroundLocation?: Location;
  };

  return (
    <Auth>
      <Routes location={state?.backgroundLocation || location}>
        <Route index={true} element={<OpenSnapshot />}/>
        <Route path="/:categorySlug/:nomineeSlug?" element={<OpenSnapshot />} />
      </Routes>
      <AuthRoutes />
    </Auth>
  )
}