import { StyleFunctionProps } from '@telescope/cassini-ui';
import { defineStyle } from '@chakra-ui/styled-system';

// Sizes
const xl = defineStyle({
  fontSize: '2xl',
  px: 10,
  py: 3,
})

export const buttonTheme = {
  baseStyle: { 
    _disabled: { pointerEvents: 'none' },
    _hover: { textDecoration: 'none' },
    fontFamily: 'Button.text',
    fontWeight: 'normal',
    rounded: 0,
    h: 'auto',
    touchAction: 'manipulation'
  },
  variants: {
    solid: (props: StyleFunctionProps) => {
      return ({
        bg: `Button.primary.default.bg`,
        color: `Button.primary.default.text`,
        _hover: 'unset',
        _active: 'unset',
        '@media (hover: hover) and (pointer: fine)': {
          _hover: {
            bg: `Button.primary.hover.bg`,
            color: `Button.primary.hover.text`
          }
        }
      })
    },
    link: (props: StyleFunctionProps) => ({
      color: 'Link.text',
      _hover: {
        textDecoration: 'none'
      },
      _active: 'unset'
    }),
    inverse: {
      bg: `Button.primary.hover.bg`,
      color: `Button.primary.hover.text`,
      _hover: 'unset',
      '@media (hover: hover) and (pointer: fine)': {
        _hover: {
          bg: `Button.primary.default.bg`,
          color: `Button.primary.default.text`,
        }
      },
    },
    twitter: {
      bg: '#000',
      _hover: {
        bg: '#000',
      }
    },
    google: {
      bg: '#5285EC',
      _hover: {
        bg: '#5285ECDD'
      }
    },
    facebook: {
      bg: 'facebook.500',
      _hover: {
        bg: 'facebook.600'
      }
    }
  },
  sizes: { 
    md: {
      fontSize: 'sm',
      pt: 1
    },
    xl 
  },
  defaultProps: {
    variant: 'solid'
  }
};
