import React, { useMemo } from 'react';
import { 
  AuthProvider,
  Google,
  Email
} from '../providers/use-auth';
import { SessionProvider } from 'features/auth/lib';
import { useVote } from '@telescope/cassini-hooks';
import { useWidget } from 'providers';

import { OpenSnapshot } from 'types';

type AuthProps = {
  children: React.ReactNode
}

type MethodId = keyof OpenSnapshot['widget']['auth']['methods'];

export const Auth = ({ children }: AuthProps) => {
  const authHandler = useVote();
  
  const { data: auth } = useWidget({ select: (data: OpenSnapshot) =>  data.widget.auth });
  const { methods } = auth!;

  const providers: Record<MethodId, any> = {
    email: Email(),
    google: Google({
      clientId: methods.google.content.googleClientId
    })
  }

  const enabledProviders = useMemo(() => Object.entries(methods).reduce((acc: any[], [key, value]) => {
    if (value.isEnabled) {
      acc.push(providers[key as MethodId])
    }
    return acc
  }, []), [methods])

  return (
    <AuthProvider 
      providers={enabledProviders}
      handler={authHandler}>
        <SessionProvider>
          { children }
        </SessionProvider>
    </AuthProvider>
  )
}