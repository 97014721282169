export const containerTheme = {
  baseStyle: { 
    w: '100%',
    maxW: 'none',
    px: {
      base: 4,
      xs: 6,
      md: 8
    } 
  },
};
