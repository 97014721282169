import { Fragment, useMemo } from 'react';
import { IconButton, VStack, HStack, Box, IconButtonProps } from '@telescope/cassini-ui';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import { Text } from 'components';
import { NomineeMedia } from '.';
import { NomineeMediaData, MultiVoteSnapshot } from 'types';
import { useWidget, useTracking } from 'providers';
import reactStringReplace from 'react-string-replace';
import { useVotingGrid, VoteAction, slugify } from 'features';

type NomineeCardProps = {
  data: NomineeMediaData,
  handleVoteUpdate: (voteAction: VoteAction, id: string) => void
  categorySlug: string
  isDisabled?: boolean
  isPlusEnabled?: boolean
  isMinusEnabled?: boolean
}

export const MultiVoteNominee = ({ categorySlug, data, handleVoteUpdate, isDisabled = false, isPlusEnabled = true, isMinusEnabled = true } : NomineeCardProps) => {
  const { data: votingData } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.vote })
  const { content } = votingData!;

  const { getNomineeVotes, getCategoryBySlug } = useVotingGrid();

  const categoryId = getCategoryBySlug(categorySlug).category_key;
  const allocatedVotes = useMemo(() => getNomineeVotes(categoryId, data.id), [getNomineeVotes, data.id]);

  const { trackClickEvent } = useTracking();

  const handleButtonClick = (action: VoteAction, id: string) => {
    trackClickEvent({
      name: `${action === VoteAction.ADD_VOTE? 'plus' : 'minus'}_vote_${categorySlug}_${slugify(data.name)}`,
      section: categorySlug,
      url: window.location.href
    });

    handleVoteUpdate(action, id);
  }

  return (
    <Box>
      <NomineeMedia {...data} categorySlug={categorySlug} />

      <HStack spacing={10} justifyContent="center" bg="MultiVoteCard.bg" p={2} shadow="0px 2px 4px 0px rgba(0, 0, 0, 0.08)">
        <VoteButton aria-label='Subtract Vote' icon={<AiOutlineMinus />} 
          onClick={() => handleButtonClick(VoteAction.SUBTRACT_VOTE, data.id)}
          isDisabled={!allocatedVotes || isDisabled || !isMinusEnabled} />

        <VStack color="MultiVoteCard.text" spacing={1}>
          {reactStringReplace(content.progress.nominee, '{{VOTES}}', (match: string | undefined, i: number) => (
            <Fragment key={i}>
              <Text fontSize="2.7rem" fontWeight={800} fontFamily="MultiVoteCard.count"> {allocatedVotes} </Text>
              <Text fontSize="xs" fontFamily="MultiVoteCard.text">Votes</Text>
            </Fragment>
          ))}
        </VStack>

        <VoteButton aria-label='Add Vote' icon={<AiOutlinePlus />} 
          onClick={() => handleButtonClick(VoteAction.ADD_VOTE, data.id)}
          isDisabled={isDisabled || !isPlusEnabled} />
        
      </HStack>
    </Box>
  )
}

const VoteButton = (props: IconButtonProps) => {
  return (
    <IconButton
      isRound size="lg" 
      bg="MultiVoteCard.button.default.bg" color="MultiVoteCard.button.default.icon"
      _hover={{ 
        '@media (hover: hover) and (pointer: fine)': {
          bg: 'MultiVoteCard.button.hover.bg',
          color: 'MultiVoteCard.button.hover.icon'
        }
      }} 
      _disabled={{ 
        bg: 'MultiVoteCard.button.disabled.bg',
        color: 'MultiVoteCard.button.disabled.icon',
        cursor: 'initial'
      }}
      {...props} 
    />
  )
}