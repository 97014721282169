import { InstagramButton, TwitterButton, FacebookButton, SnapchatButton, WhatsAppButton } from '.';
import { OpenSnapshot, CategoryT } from 'types';

type SocialButtonData =  NonNullable<CategoryT<OpenSnapshot>['socialButtons']>[0] & {
  onClick: () => void
};

export type SocialButtonProps = Omit<SocialButtonData, 'type'>;

export const SocialButton = ({ type, ...props } : SocialButtonData) => {
  switch (type) {
    case 'facebook':
      return <FacebookButton {...props} />
    case 'instagram':
      return <InstagramButton {...props} />
    case 'snapchat':
      return <SnapchatButton {...props} />
    case 'twitter':
      return <TwitterButton {...props} />
    case 'whatsapp':
      return <WhatsAppButton {...props} /> 
    default:
      return null
  }
}