export {};

declare global {
  interface Window {
    wid: string | undefined;
    MTVNavigation: Record<string,any>;
    MTVFooter: Record<string,any>;
    MTVUpsellBanner: Record<string,any>;
    MTVUpSellDialog: Record<string,any>;
    mtvNavAssetRoot: string;
    mtvHeaderUrl: string;
    mtvNavigationBaseUrl: string;
    mtvCampaignId: string;
    BidBarrel: any;
    cbsoptanon: any;
    Bento: any;
    dataLayer: any[];
    tsReactInitConfig: {
      widgetStateUrl: string;
      connectUrl: string;
      previewUrl: string;
      apiHash: string;
      basename: string;
    }
  }
}

window.wid = window.wid || undefined;