
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useVotingGrid } from '../providers';
import { useWidget } from 'providers';
import { Campaign } from 'types';
import { useMemo } from 'react';

export const Metatag = () => {
  const { getCategoryBySlug } = useVotingGrid();
  const { categorySlug } = useParams();

  const { data: widgetData } = useWidget({ select: (data: Campaign) => data.widget });
  const { settings: { metatags } } = widgetData!;

  const category = useMemo(() => categorySlug? getCategoryBySlug(categorySlug) : null, [categorySlug]);

  if (category) {
    const title = metatags.category.title || metatags.title || '';
    const description = metatags.category.description || metatags.description || '';
    
    return (
      <Helmet>
        <title>{title.replace('{{CATEGORY}}', category.category_title)}</title>
        <meta name="description" content={description.replace('{{CATEGORY}}', category.category_title)}/>
        <link rel="image_src" href={category.shareImage || metatags.image}></link>
      </Helmet>
    )
  }

  return (
    <Helmet>
      <title>{metatags.title}</title>
      <meta name="description" content={metatags.description} />
      <link rel="image_src" href={metatags.image}></link>
    </Helmet>
  )
}