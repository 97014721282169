import { getFromQsp } from '../../utils';
import { ProviderType } from '../../types';

export const OAuthError = {
  GENERIC: 'generic'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type OAuthError = typeof OAuthError[keyof typeof OAuthError];

type OAuthProviderProps = {
  id: string 
  url: string
  getUserData?: (accessToken: string) => any
  tokenParameter?: string,
  type?: ProviderType
}

export const OAuth = ({ 
  id, 
  url,
  getUserData,
  tokenParameter
}: OAuthProviderProps) => {

  const authenticate = ({ redirectUri = `${window.location.href}/callback/${id}`, state } : { redirectUri: string, state: string }) => {
    const oauthUrl = new URL(url);
    oauthUrl.searchParams.append('redirect_uri', redirectUri);
    oauthUrl.searchParams.append('state', state);

    window.location.href = oauthUrl.toString();
  }

  const callback = async () => {
    const accessToken = getFromQsp(tokenParameter?? 'code');
    const error = getFromQsp('error');

    if (!accessToken || error) {
      throw Error(error || OAuthError.GENERIC);
    };

    try {
      let state = getFromQsp('state');
      state = state && JSON.parse(window.atob(state));
  
      if (!getUserData) {
        return { payload: { code: accessToken, method: id }, state };
      }

      const user = await getUserData(accessToken);

      return { payload: { ...user, method: id }, state };
    } catch (error: any) {
      throw Error(error.message || OAuthError.GENERIC);
    }
  }

  return {
    id,
    type: ProviderType.OAUTH,
    authenticate,
    callback
  }
}