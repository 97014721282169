import { CheckboxField, DatePickerField, PhoneNumberField, SelectField, TextField } from 'components';
import { InputType, INPUT_MAP, US_STATES } from './constants';
import merge from 'deepmerge';

export const renderInput = (data: any) => {
  const { fieldType, name: fieldName } = data;
  const fieldPreset = INPUT_MAP[fieldType as keyof typeof INPUT_MAP];
  const field = merge(fieldPreset as any, data) as any;
  const name = fieldName || field.fieldType;
 
  switch (field.type) {
    case InputType.EMAIL: {
      return (
        <TextField
          name={name}
          label={field.label}
          type="email"
          isRequired={data.required}
          maxLength={field.maxLength}
          autoComplete="on"
          defaultValue={''}
          rules={{ required: { value: data.required, message: field.errorMessage }, ...field.rules }}
          isDisabled={field.isDisabled}
          isReadOnly={field.isDisabled}
          placeholder={field.placeholder}
        />
      );
    }
    case InputType.CHECKBOX: {
      return (
        <CheckboxField
          name={name}
          label={field.label}
          isRequired={field.required}
          defaultChecked={field.prechecked ?? false}
          defaultValue={field.prechecked ?? false}
          rules={{ required: { value: field.required, message: field.errorMessage }, ...field.rules }}
        />
      );
    }
    case InputType.SELECT: {
      return (
        <SelectField
          name={name}
          label={field.label}
          isRequired={field.required}
          rules={{ required: { value: field.required, message: field.errorMessage }, ...field.rules }}
          selectProps={{ options: US_STATES as any }}
          placeholder={field.placeholder}
        />
      );
    }
    case InputType.PHONE: {
      return (
        <PhoneNumberField
          format="(###) ###-####"
          defaultValue=""
          mask="_"
          name={name}
          label={field.label}
          isRequired={field.required}
          placeholder={field.placeholder}
          rules={{ required: { value: field.required, message: field.errorMessage }, ...field.rules }}
        />
      );
    }
    case InputType.DATE: {
      return (
        <DatePickerField
          name={field.fieldType}
          label={field.label}
          defaultValue=""
          type="date"
          isRequired={field.required}
          rules={{ ...field.validation, required: { value: field.required, message: field.errorMessage } }}
          max={field.max}
          min={field.min}
        />
      );
    }
    case InputType.TEXT: {
      return (
        <TextField
          name={name}
          label={field.label}
          type="text"
          isRequired={field.required}
          maxLength={field.maxLength}
          autoComplete="on"
          rules={{ ...field.validation, required: { value: field.required, message: field.errorMessage } }}
          placeholder={field.placeholder}
        />
      );
    }
    case InputType.NUMBER: {
      return (
        <TextField
          name={name}
          label={field.label}
          type="number"
          isRequired={field.required}
          rules={{ ...field.validation, required: { value: field.required, message: field.errorMessage } }}
          placeholder={field.placeholder}
        />
      );
    }
  }
};
