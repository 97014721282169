import { Box, Button, Spinner } from '@telescope/cassini-ui';
import { NomineeMedia } from '.';
import { NomineeMediaData as NomineeMediaData, SingleVoteSnapshot, ErrorType } from 'types';
import { VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { useWidget, useTracking } from 'providers';
import { useVotingGrid, delay, useModals, ErrorPage, useSession } from 'features';
import { useCallback, useState } from 'react';
import { FaCheck } from 'react-icons/fa6';

// TODO: update template to include overlimit ('Limit Reached') text:
// https://telescopetv.atlassian.net/browse/MTV-232

type NomineeCardProps = {
  data: NomineeMediaData
  categorySlug: string
  hasVotesLeft?: boolean
}

const ButtonStatus = {
  LOADING: 'loading',
  COMPLETE: 'complete',
  DEFAULT: 'default'
} as const; 

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ButtonStatus = typeof ButtonStatus[keyof typeof ButtonStatus];

export const SingleVoteNominee = ({ categorySlug, data, hasVotesLeft } : NomineeCardProps) => {
  const { data: votingData } = useWidget({ select: (data: SingleVoteSnapshot) => data.snapshot.snapshot_views.vote });

  const { openModal } = useModals();

  const { content } = votingData!;

  const [status, setStatus] = useState<ButtonStatus>(ButtonStatus.DEFAULT);

  const { trackClickEvent } = useTracking();

  const { handleSingleSubmit, getCategoryBySlug, setPendingVote } = useVotingGrid();
  const { validateSession } = useSession();

  const handleSubmit = useCallback(async () => {
    const categoryId = getCategoryBySlug(categorySlug).category_key;
    if (!validateSession()) {
      setPendingVote({ categoryId, nomineeId: data.id });
      return;
    }

    setStatus(ButtonStatus.LOADING);

    try {
      await handleSingleSubmit(categoryId, data.id);
      await delay(1000);
      setStatus(ButtonStatus.COMPLETE);
      await delay(500);
      setStatus(ButtonStatus.DEFAULT)
    } catch(error: any) {
      openModal({
        children: <ErrorPage 
          errorType={error?.message === VoteAPIResponseCode.OVERLIMIT? ErrorType.OVERLIMIT : ErrorType.VOTE} />
      });
    } finally {
      setStatus(ButtonStatus.DEFAULT);

      trackClickEvent({
        name: `submit_vote_button|${categorySlug}`,
        section: categorySlug,
        url: window.location.href
      });
    }
  }, [validateSession])

  return (
    <Box>
      <NomineeMedia {...data} categorySlug={categorySlug} />

      <Button onClick={handleSubmit} w="full" size="xl" py={7} 
        _disabled={{bg: 'SingleVoteCard.button.default.bg', color: 'SingleVoteCard.button.default.text', cursor: 'initial'}}
        _hover={{'@media (hover: hover) and (pointer: fine)': {
          bg: 'SingleVoteCard.button.hover.bg', color: 'SingleVoteCard.button.hover.text',
          _disabled:{bg: 'SingleVoteCard.button.default.bg', color: 'SingleVoteCard.button.default.text'}}}} 
        opacity={hasVotesLeft? 1 : .6}
        color={status === ButtonStatus.LOADING? 'SingleVoteCard.button.default.loading' : 'SingleVoteCard.button.default.text'}
        isDisabled={status === ButtonStatus.LOADING || !hasVotesLeft}
        isLoading={status !== ButtonStatus.DEFAULT} 
        spinner={status === ButtonStatus.COMPLETE? <FaCheck/> : <Spinner/> }>
        {hasVotesLeft? content.voteButton : 'Limit Reached'}
      </Button>
    </Box>
  )
}