import { Accordion } from '@telescope/cassini-ui';
import { Category } from './category';
import { useVotingGrid } from '../providers';
import { Campaign, SnapshotType } from 'types';
import { Metatag } from 'features';
import { useWidget } from 'providers';
import { useEffect, useMemo } from 'react';
import { isEmpty } from 'lodash-es';

export function VoteGrid<SnapshotT extends Campaign>() {
  const { categories, onCategoryChange, expandedCategoryIndexes } = useVotingGrid<SnapshotT>();
  const { data: snapshotType } = useWidget({ select: (data: Campaign) => data.snapshot.type });

  const isClosed = snapshotType === SnapshotType.CLOSED;
  const categoryIndexes = useMemo(() => 
    Array.from(Array(Object.keys(categories).length), (_, index) => index), [categories]);
  
  const shouldExpandAll = useMemo(() => isClosed && !expandedCategoryIndexes.length, [isClosed])

  if (isEmpty(categories)) return null;

  return (
    <>
    <Metatag />
    <Accordion  
      {...(isClosed && {defaultIndex: categoryIndexes})}
      index={shouldExpandAll? undefined : expandedCategoryIndexes}
      allowMultiple={true}
      allowToggle onChange={onCategoryChange} flexGrow={2}>
      {Object.keys(categories).map((categorySlug, i) => 
        <Category key={i} slug={categorySlug} />)}
    </Accordion>
    </>
  )
}