import { FormControlProps } from '@chakra-ui/form-control';
import { useMergeRefs } from '@telescope/cassini-hooks';
import { FormControl, FormLabel, FormHelperText, FormErrorMessage } from '@telescope/cassini-ui';
import { GroupBase, OptionBase, Select } from 'chakra-react-select';
import React, { forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type SelectFieldProps = UseControllerProps &
  FormControlProps & { helpText?: string; selectProps: GroupBase<OptionBase>, placeholder?: string };

export const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      isRequired,
      label,
      helpText,
      isDisabled,
      isReadOnly,
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
      placeholder,
      selectProps,
      ...formControlProps
    },
    ref
  ) => {
    const {
      field: { onChange, onBlur, value, ref: fieldRef },
      fieldState: { error },
    } = useController({
      name,
      rules,
      defaultValue,
      shouldUnregister,
    });
    const selectElRef = useMergeRefs(ref, fieldRef);
    const { options, ...rest } = selectProps;

    return (
      <FormControl
        isInvalid={!!error}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        {...formControlProps}>
        <FormLabel>{label}</FormLabel>
        <Select
          selectedOptionStyle="color"
          selectedOptionColor="primary"
          chakraStyles={{
            menuList: (provided) => {
              return { ...provided, bg: 'surface' };
            },
            option: (provided, state) => {
              return { ...provided, ...(state.isFocused && { color: 'black' }) };
            },
            dropdownIndicator: (provided) => {
              return { ...provided, bg: 'primary.500' };
            },
          }}
          name={name}
          ref={selectElRef}
          onBlur={onBlur}
          value={options && value ? options.find((option: any) => option.value === value) : null}
          onChange={(option: any) => onChange(option.value)}
          options={options}
          placeholder={placeholder}
          {...rest}
        />

        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
);
