import { Box, Text, IconButton, HStack } from '@telescope/cassini-ui';
import { FaFacebook } from 'react-icons/fa';
import { IoMdMail } from 'react-icons/io';
import { FaXTwitter } from 'react-icons/fa6';
import { MultiVoteSnapshot } from 'types';
import { useWidget, useTracking } from 'providers';

export const Share = () => {
  const { data } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.thanks });
  const { share } = data!;

  const { trackClickEvent } = useTracking();

  const handleClickTracking = (platform: string) => {
    trackClickEvent({
      name: `thanks ${platform} share button`,
      section: 'thanks-modal', 
      url: window.location.href
    })
  }

  const handleTwitterShare = () => {
    const { copy, link } = share.twitter;

    const url = new URL('https://twitter.com/intent/tweet');
    if (copy) url.searchParams.append('text', copy);
    if (link) url.searchParams.append('url', link);

    window.open(url, 'Twitter Share', 'width=550,height=420');

    handleClickTracking('twitter');
  }

  const handleFacebookShare = () => {
    const { shareUrl = window.location.href } = share.facebook;

    const url = new URL('https://www.facebook.com/sharer/sharer.php');
    url.searchParams.append('u', shareUrl);
    window.open(url, 'popup', 'width=600, height=400, scrollbars=no');

    handleClickTracking('facebook');
  }

  const handleEmailShare = () => {
    const { subject = '', body = '' } = share.email;

    window.open(`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);

    handleClickTracking('email');
  }

  return (
    <Box>
      <Text fontSize="xs" mb={2}>Share</Text>
      <HStack spacing={4}>
        <IconButton icon={<FaXTwitter size={20}/>} onClick={handleTwitterShare} color="white"
          aria-label="Share on Twitter" variant="twitter" isRound/>
        <IconButton icon={<FaFacebook size={20}/>} onClick={handleFacebookShare} color="white"
          aria-label="Share on Facebook" variant="facebook" _hover={{bg: 'facebook.500', color: 'white'}} isRound/>
        <IconButton icon={<IoMdMail size={20}/>} onClick={handleEmailShare} color="Share.email.icon"
          aria-label="Share on Email" bg="Share.email.bg" _hover={{bg: 'Share.email.hover', color: 'Share.email.icon'}} isRound/>
      </HStack>
    </Box>
  )
}