import { IconType } from 'react-icons';
import {
  FaFacebook,
  FaInstagram,
  FaTiktok,
  FaSnapchat,
  FaTumblr,
  FaTwitch,
  FaYoutube,
  FaPinterest,
  FaEnvelope
} from 'react-icons/fa';
import { FaXTwitter, FaThreads } from 'react-icons/fa6'

export const iconMap = {
  facebook: FaFacebook,
  snapchat: FaSnapchat,
  threads: FaThreads,
  twitter: FaXTwitter,
  tiktok: FaTiktok,
  youtube: FaYoutube,
  twitch: FaTwitch,
  tumblr: FaTumblr,
  instagram: FaInstagram,
  pinterest: FaPinterest,
  email: FaEnvelope,
} as Record<string, IconType>;
