import { anatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/styled-system';

/**
 * **TimeStat anatomy**
 * - Root: the root container of the time stat
 * - Value: the value of the displayed stat
 * - Label: the label displayed
 */
const timeStatAnatomy = anatomy('timeStat').parts('root', 'value', 'label');
const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(timeStatAnatomy.keys);

const baseStyleRoot = defineStyle({
  textAlign: 'center',
});

const baseStyleLabel = defineStyle({
  
});

const baseStyleValue = defineStyle({
  fontWeight: 'bold',
  marginBottom: '2',
});

const baseStyle = definePartsStyle({
  root: baseStyleRoot,
  label: baseStyleLabel,
  value: baseStyleValue,
});

const sizes = {
  md: definePartsStyle({
    value: { fontSize: '2xl' },
    label: { fontSize: 'sm' },
  }),
};

const variantTight = definePartsStyle({
  root: {
    px: 0,
    py: 0,
  }
});

const variantCondensed = definePartsStyle({
  root: {
    px: [3, 6],
    py: [2, 4],
  },
});

const variantRelaxed = definePartsStyle({
  root: {
    px: [5, 8],
    py: [2, 4],
  },
});

const variants = {
  condensed: variantCondensed,
  relaxed: variantRelaxed,
  tight: variantTight
};

export const timeStatTheme = defineMultiStyleConfig({
  baseStyle,
  sizes,
  variants,
  defaultProps: {
    size: 'md',
    variant: 'tight',
  },
});
