import { cx } from '@chakra-ui/shared-utils';
import { HTMLChakraProps, chakra, forwardRef } from '@chakra-ui/system';
import { useTimeStatStyles } from './TimeStat';

export interface TimeStatLabelProps extends HTMLChakraProps<'dt'> {}

export const TimeStatLabel = forwardRef<TimeStatLabelProps, 'dt'>(function TimeStatLabel(props, ref) {
  const styles = useTimeStatStyles();
  return (
    <chakra.dt ref={ref} {...props} className={cx('cassini-time-stat__label', props.className)} __css={styles.label} />
  );
});

TimeStatLabel.displayName = 'TimeStatLabel';
