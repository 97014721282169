import { SocialColorScheme } from 'types/app';
import { Theme } from '../../types';
import Color from 'color';

export const cmtBrandedFonts = [
  'https://content.votenow.tv/app/projects/mtv/cmt/fonts/stylesheet.css'
];

const FONTS = {
  TITLING_GOTHIC_COMP: 'Titling Gothic Comp, sans-serif',
  TITLING_GOTHIC_NARROW_REGULAR: 'Titling Gothic Narrow Regular, sans-serif',
  TITLING_GOTHIC_NARROW_STANDARD: 'Titling Gothic Narrow Standard, sans-serif',
}

export const cmtBrandedCategoryBg = {
  bgImage: 'https://ssp-campaigns-tailor.votenow.tv/ea5b9473a7da661f9641a4c650abe9e6_0_2.jpeg',
  bgPosition: 'bottom',
  backgroundAttachment: 'fixed',
  bgRepeat: 'no-repeat',
  bgSize: 'cover'
};

export const cmtBrandedSocialColorScheme: SocialColorScheme = SocialColorScheme.MONOCHROME;

export const cmtBrandedTheme: Theme = {
  colors: {
    brand1: '#F8E600',
    brand2: '#00B1E2',
    brand3: '#262220',
    // text
    text1: '#F1EBEB',
    text2: '#D7C4C4',
    text3: '#FFFFFF',
    text4: '#808284',
    // ui
    ui2: '#4B161E',
    ui3: '#FFFFFF',
    ui4: '#201D1B',
    ui5: '#0A283D',
    ui6: '#171514',
    // support
    support1: '#0072A1',
    support2: '#F8E600',
    support3: '#005377',
    support4: '#E4002B'
  },
  fonts: {
    heading: FONTS.TITLING_GOTHIC_COMP,
    body: FONTS.TITLING_GOTHIC_NARROW_STANDARD
  },
  styles: {
    global: {
      '*': {
        boxSizing: 'border-box',
        '::selection': {
          bg: 'brand2'
        }
      },
      html: {
        touchAction: 'manipulation'
      },
      body: {
        color: 'ui6',
        bg: '#4B161E',
        lineHeight: 'shorter'
      },
    },
  },
  semanticTokens: {
    colors: {
      'chakra-placeholder-color': 'brand3',
      '--modal-bg': 'transparent',
      Button: {
        primary: {
          default: {
            bg: 'brand2',
            text: 'brand3'
          },
          hover: {
            bg: 'brand3',
            text: 'text3'
          }
        },
        secondary: {
          default: {
            bg: 'ui3',
            text: 'brand3'
          },
          hover: {
            bg: 'brand3',
            text: 'text3'
          }
        }
      },
      Link: {
        text: 'text3'
      },
      Header: {
        bg: '#201D1B'
      },
      HeroSection: {
        textScheme: {
          bg: 'ui3',
          text: 'ui6'
        },
        imageScheme: {
          text: 'text1'
        }
      },
      CategoryButton: {
        bg: 'ui2',
        heading: 'text3',
        text: 'text2',
      },
      CategoryGrid: {
        bg: 'ui2',
        text: 'text3',
      },
      NomineeMedia: {
        text: 'text1',
        detail: 'support2',
        winner: {
          bg: 'support1',
          text: 'text1'
        }
      },
      MultiVoteCard: {
        bg: 'ui2',
        text: 'text3',
        button: {
          default: {
            bg: 'ui3',
            icon: 'brand3',
          },
          hover: {
            bg: 'brand3',
            icon: 'ui3',
          },
          disabled: {
            bg: '#FFFFFF20',
            icon: 'brand3',
          }
        }
      },
      SingleVoteCard: {
        button: {
          default: {
            bg: 'Button.primary.default.bg',
            text: 'Button.primary.default.text',
            loading: 'Button.primary.default.text'
          },
          hover: {
            bg: 'Button.primary.hover.bg',
            text: 'Button.primary.hover.text',
          }
        }
      },
      ProgressBar: {
        bg: 'ui3',
        text: 'ui6'
      },
      TurboBanner: {
        bg: 'ui6',
        text: 'text1'
      },
      Countdown: {
        label: {
          bg: 'support1',
          color: 'text1'
        },
        text: 'text1'
      },
      InfoContainer: {
        bg: 'ui4'
      },
      Sponsor: {
        text: {
          light: 'text2',
          dark: 'ui6'
        }
      },
      UpsellBanner: {
        bg: 'support1',
        text: 'text3'
      },
      UpsellModal: {
        bg: 'ui2',
        text: 'text3'
      },
      Footer: {
        bg: 'ui4',
        link: {
          default: 'text2',
          hover: 'brand2'
        },
        text: 'text2'
      },
      Form: {
        error: 'support4',
        input: {
          bg: 'ui3',
          text: 'ui6',
        },
        google: {
          default: {
            bg: 'brand2',
            text: 'brand3'
          },
          hover: {
            bg: 'brand3',
            text: 'text3'
          }
        }
      },
      Modal: {
        overlay: 'rgba(0, 0, 0, 0.80)',
        bg: 'ui6',
        text: 'text1',
        icons: 'text1'
      },
      Login: {
        legal: 'text2'
      },
      Share: {
        email: {
          icon: 'Button.primary.default.text',
          bg: 'ui3',
          hover: 'ui3'
        }
      },
      SocialButton: {
        icon: 'text3',
        text: 'brand3',
        bg: 'brand2'
      }
    },
    fonts: {
      Button: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      CategoryButton: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      NomineeMedia: {
        winner: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      MultiVoteCard: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        count: FONTS.TITLING_GOTHIC_COMP
      },
      UpsellBanner: {
        text: FONTS.TITLING_GOTHIC_NARROW_REGULAR,
        button: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      UpsellModal: {
        text: FONTS.TITLING_GOTHIC_NARROW_REGULAR,
      },
      Header: {
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      Footer: {
        link: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Login: {
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        legal: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Form: {
        input: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      },
      VideoPlayer: {
        heading: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
        text: FONTS.TITLING_GOTHIC_NARROW_STANDARD,
      },
      Countdown: {
        number: FONTS.TITLING_GOTHIC_COMP,
        label: FONTS.TITLING_GOTHIC_NARROW_STANDARD
      }
    },
    letterSpacings: {
      CategoryButton: {
        heading: '3px'
      },
    }
  }
}
