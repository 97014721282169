import { AspectRatio, VStack, Box, Skeleton } from '@telescope/cassini-ui';
import { Text, Heading, Display, Link, Image } from 'components';
import { slugify } from 'features';
import { LuChevronRight } from 'react-icons/lu';
import { OpenSnapshot } from 'types';
import { useWidget, useTracking } from 'providers';


type NomineeMediaProps = {
  image?: string,
  name: string,
  description?: string,
  secondaryDescription?: string,
  isWinner?: boolean,
  videoUrl?: string,
  categorySlug: string
}
 
export const NomineeMedia = ({ image, name, description, secondaryDescription, isWinner, videoUrl, categorySlug } : NomineeMediaProps) => {
  const { data: votingData } = useWidget({ select: (data: OpenSnapshot) => data.snapshot.snapshot_views.vote })
  const { content } = votingData!;

  const { trackClickEvent } = useTracking();
  const trackVideoClick = () => {
    trackClickEvent({
      name: `video|${categorySlug}|${slugify(name)}`,
      section: categorySlug,
      url: window.location.href
    });
  }

  return (
    <AspectRatio ratio={1} order={isWinner? -1 : 'unset'}>
      <>
      <Image src={image} alt="" w="full" fallback={<Skeleton />} useWebp />
      <Box bgGradient="linear(transparent, rgba(0,0,0,.6))">
        <VStack alignItems="flex-start" justifyContent="flex-end" 
                w="100%" h="100%" p={6} spacing={2} color="NomineeMedia.text">
          <Display hide={!isWinner}>
            <Text bg="NomineeMedia.winner.bg" color="NomineeMedia.winner.text" 
              fontSize="xs" p="6px 5px 3px" mb={4} fontFamily="NomineeMedia.winner">
              {(content.nomineeMedia as any).winnerTag}
            </Text>
          </Display>

          <Heading as="h3" fontSize="2xl" color="inherit">{name}</Heading>
          <Text fontSize="sm">{description}</Text>
          <Text fontSize="sm" color="NomineeMedia.detail">{secondaryDescription}</Text>
          <Display hide={!videoUrl}>
            <Link href={`/${categorySlug}/${slugify(name)}`} fontSize="sm" color="NomineeMedia.detail" 
              mt={2} sx={{svg: {display: 'inline', verticalAlign: 'middle', transform: 'translateY(-1px)'}}}
              onClick={trackVideoClick}>
                {content.nomineeMedia.videoLinkText}<LuChevronRight size={20}/>
            </Link>
          </Display>
        </VStack>
      </Box>
      </>
    </AspectRatio>
  )
}

