import { Button, Link } from '@telescope/cassini-ui';
import { FaInstagram } from 'react-icons/fa';
import { SocialButtonProps } from './SocialButton';
import { socialColorScheme } from 'theme';
import { SocialColorScheme } from 'types';

export const InstagramButton = ({ copy, ...props } : SocialButtonProps) => {
  const isMonochrome = socialColorScheme === SocialColorScheme.MONOCHROME;
  return (
    <Button as={Link}  target="_blank"
            leftIcon={<FaInstagram size={26} color={isMonochrome? 'var(--cassini-colors-SocialButton-icon)' : 'currentColor'}/>}
            fontSize="sm" py={6}
            {...(isMonochrome? {
                bg: 'SocialButton.bg', color: 'SocialButton.text'
              } : {
                bgGradient: 'linear(to-r, #9B3675, #7B428C)', color: 'white'
            })}
            _hover={{textDecoration: 'none'}} _active={{}}
            {...props}>
      {copy}
    </Button>
  )
}