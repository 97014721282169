import { Box, Container, HStack, IconButton, VStack } from '@telescope/cassini-ui';
import { Link, Text } from 'components';
import { useMtvData } from 'hooks';
import parse from 'html-react-parser';
import { iconMap } from './utils';
import React from 'react';
import { useTracking, useWidget } from 'providers';
import { Campaign } from 'types';

export const Footer = () => {
  const data = useMtvData('MTVFooter', 'MTVFooterLoaded');

  const { data: header } = useWidget({ select: (data: Campaign) => data.widget.global_views.footer });
  const { content } = header!;

  const { trackClickEvent } = useTracking();

  if (!data) return null;

  return (
    <Container w="full" as="footer" centerContent px={{base: "2.5rem", lg: "4.5rem"}} pt={10} pb={{base: 20, lg: 24}} maxW="none" bg="Footer.bg" color="Footer.text">
      <VStack spacing={12}>

        <HStack wrap="wrap" columnGap={5} rowGap={3} justifyContent="center">
          {data.navigationEntries?.[0]?.navigationEntries?.
            map((link: { title: string, url: string, id: string }, i: number) => 
            {
              const isExternal = /https?:\/\//.test(link.url);
              const isJs = /^javascript:/.test(link.url);
              return (
                <Link href={(isExternal || isJs)? link.url : `${window.mtvNavigationBaseUrl}${link.url}`} key={link.id} 
                  fontSize="xs" fontFamily="Footer.link" 
                  color="Footer.link.default" _hover={{color: 'Footer.link.hover'}}
                  textTransform="uppercase"
                  onClick={() => trackClickEvent({name: `footer|${link.title}`, destination: link.url, modname: 'footer'})}>
                  {link.title}
                </Link>
              )
            })}
        </HStack>

        <HStack wrap="wrap" fontSize={28}>
          {content.followLinks?.map((link: { icon: string; href: string; alt: string }, index: number) => {
            return (
              <IconButton
                data-social-name={link.icon}
                className="cassini-footer__follow-links-icon"
                fontSize="inherit"
                variant="link"
                key={index}
                as="a"
                href={link.href}
                aria-label={link.alt}
                target="_blank"
                icon={React.createElement(iconMap[link.icon])}
                color="Footer.link.default" _hover={{color: 'Footer.link.hover'}}
                onClick={() => trackClickEvent({name: `footer:social| ${link.icon}`, destination: link.href, modname: 'footer'})}
              />
            );
          })}
          </HStack>

          <Text fontSize="xs" fontFamily="Footer.text" textAlign="center" color="Footer.text">
            {parse(data.copy?.replace('{CURRENT_YEAR}', new Date().getFullYear()))}
          </Text>
      </VStack>
    </Container>
  )
}