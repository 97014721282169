import { useWidget } from 'providers';
import { Campaign } from 'types';
import { VStack, Spacer, StackProps } from '@telescope/cassini-ui';
import { Heading, Text } from 'components';
import { Sponsor, useVotingGrid } from 'features';

export const HeroContent = (props: StackProps) => {
  const { data: heroSection } = useWidget({ select: (data: Campaign) => data.snapshot.snapshot_views.heroSection });
  const { copy, sponsor } = heroSection!;

  const { voteLimit } = useVotingGrid();

  return (
    <VStack maxW="39rem" mx="auto" textAlign="center" spacing={3} {...props}>
      <Heading as="h1" fontSize={['2rem', '2.5rem']}>{copy.headline}</Heading>
      <Text fontWeight="800" as="h2" fontSize={['1rem', '1.15rem']}>
        {copy.subheadline?.replace('{{VOTE_LIMIT}}', voteLimit.toString())}
      </Text>
      <Text lineHeight="short">{copy.description}</Text>
      <Spacer/>
      <Sponsor {...sponsor} />
    </VStack>
  )
}