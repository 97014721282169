import {
  Text,
  Heading,
  Button,
  Link,
  VStack,
  AspectRatio
} from '@telescope/cassini-ui';
import { Display, Image } from 'components';
import { Sponsor } from 'features/sponsor';
import { Share } from './Share';
import { useWidget, useTracking } from 'providers';
import { MultiVoteSnapshot } from 'types';
import ReactPlayer from 'react-player';
import { useEffect } from 'react';

export const Thanks = () => {
  const { data } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.thanks });
  const { headline, description, sponsor, link, image, videoUrl } = data!;

  const { trackPageView, trackClickEvent } = useTracking();
  useEffect(function trackPage() {
    trackPageView({ title: 'Thanks', name: 'voting complete_all votes submitted' });
  }, [])

  return (
    <VStack textAlign="center" spacing={4}>
      <Heading fontSize="2xl" fontWeight="normal">{headline}</Heading>

      <Image src={image.src} alt={image.alt} useWebp />
      <Display hide={!videoUrl}>
        <AspectRatio ratio={16/9} w="full">
          <ReactPlayer url={videoUrl} controls={true} width="100%" height="100%" />
        </AspectRatio>
      </Display>

      <Text fontSize="sm">{description}</Text>
      <Button as={Link} href={link.url} target="_blank" w="full"
        onClick={() => trackClickEvent({ name: `link to ${window.mtvCampaignId} site`, section: 'thanks-modal', destination: link.url })}>
        {link.text}
      </Button>
      <Share />
      <Sponsor colorMode="light" {...sponsor} />
    </VStack>
  )
}