import { FormControlProps } from '@chakra-ui/form-control';
import { useMergeRefs } from '@telescope/cassini-hooks';
import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  VisuallyHidden,
} from '@telescope/cassini-ui';
import { forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { CheckboxProps } from '@chakra-ui/react';
import { Text } from 'components';

type CheckboxFieldProps = UseControllerProps & CheckboxProps & FormControlProps & { helpText?: string };

export const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  (
    {
      isRequired,
      label,
      helpText,
      isDisabled,
      isReadOnly,
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
      ...inputProps
    },
    ref
  ) => {
    const { field, fieldState } = useController({ name, rules, defaultValue, shouldUnregister });
    const inputElRef = useMergeRefs(ref, field.ref);

    return (
      <FormControl
        isInvalid={!!fieldState.error}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}
        mb={3}>
        <Checkbox {...inputProps} {...field} ref={inputElRef} fontSize="sm">
          <VisuallyHidden>
            <FormLabel>{label}</FormLabel>
          </VisuallyHidden>
          <Text display="inline-block" allowMarkdown _after={{...(isRequired && {content:`" *"`, color: 'black', fontSize: 'md'})}}>
            {label}
          </Text>
        </Checkbox>
        {!!fieldState.error && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
);
