import { FormControlProps } from '@chakra-ui/form-control';
import { InputProps } from '@chakra-ui/input';
import { useMergeRefs } from '@telescope/cassini-hooks';
import { FormControl, FormLabel, Input, FormErrorMessage, FormHelperText } from '@telescope/cassini-ui';
import { forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';

type TextFieldProps = UseControllerProps & InputProps & FormControlProps & { helpText?: string };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      isRequired,
      label,
      helpText,
      isDisabled,
      isReadOnly,
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
      ...inputProps
    },
    ref
  ) => {
    const { field, fieldState } = useController({ name, rules, defaultValue });
    const { ref: controllerRef, ...useControllerProps } = field;
    const inputElRef = useMergeRefs(controllerRef, ref);

    return (
      <FormControl
        isInvalid={!!fieldState.error}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}>
        { label && <FormLabel>{label}</FormLabel> }
        <Input {...inputProps} {...useControllerProps} ref={inputElRef} />
        {!!fieldState.error && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
);
