import { Image as CassiniImage } from '@telescope/cassini-ui';
import { ImageProps as CassiniImageProps } from '@chakra-ui/react';
import { WebpImage } from './WebpImage';

type ImageProps = CassiniImageProps & {
  useWebp?: boolean;
};

export const Image = ({ useWebp, ...props } : ImageProps) => {
  if (useWebp) return <WebpImage {...props} />

  return (
    <CassiniImage {...props} />
  )
}