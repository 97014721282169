import { ContentModal } from 'features/modals';
import { Outlet } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@telescope/cassini-ui';
import { useSession } from '../lib';
import { ROUTES } from 'utils';

export const AuthLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as {
    backgroundLocation?: Location;
  };

  const { session } = useSession();

  if (session?.user.user_id) {
    navigate(state.backgroundLocation || ROUTES.HOME, { replace: true })
    return null;
  }

  if (!state?.backgroundLocation) {
    navigate(location.pathname, { state: { backgroundLocation: ROUTES.HOME }, replace: true })
  }

  return (
    <ContentModal isOpen
      size="xl"
      bodyProps={{ display: 'flex', flexDirection: 'column' }}
      onClose={() => navigate(state?.backgroundLocation || ROUTES.HOME, { replace: true })}
      >
      <Box w="100%" flexGrow={2}>
        <Outlet />
      </Box>
    </ContentModal>
  )
}