import { createContext, useContext, useMemo, useEffect, useState, useCallback } from 'react';
import { initializeGA4, gaEvent, gaPageView, GaPageViewParams, GaEventParams } from './ga4';
import { omnitureClickEvent, omniturePageEvent, OmnitureClickEventParams, OmniturePageEventParams, RETRY_SETTINGS } from './omniture';
import { useWidget } from 'providers';
import { Campaign, SnapshotType } from 'types';
import retry from 'async-retry';

type TrackingContextValue = {
  trackGaEvent?: (...args: GaEventParams) => void
  trackGaPageView?: (...args: GaPageViewParams) => void
  trackOminitureEvent?: ({...args}: OmnitureClickEventParams) => void
  trackOminiturePage?: ({...args}: OmniturePageEventParams) => void
  trackPageView: ({ title, name, url } : { title: string, name: string, url?: string }) => void
  trackClickEvent: ({...args}: OmnitureClickEventParams) => void
  pageName: string | undefined,
  setPageName: (name: string | undefined) => void
}

const TrackingContext = createContext<TrackingContextValue | undefined>(undefined);

export const TrackingProvider = ({children} : {children: React.ReactNode}) => {
  const { data: measurementId } = useWidget({ select: (data: Campaign) => data.widget.settings.general.googleAnalytics.gaProductMeasurementId });
  const { data: snapshotType } = useWidget({ select: (data: Campaign) => data.snapshot.type });

  const [isGaAllowed, setIsGaAllowed] = useState<boolean|undefined>(undefined);
  const [gaQueue, setGaQueue] = useState<any[]>([]);

  const [shouldTrackOmniturePage, setShouldTrackOmniturePage] = useState(true);

  const [pageName, setPageName] = useState<string | undefined>('');

  useEffect(function handleGaOptanonPermissions() {
    if (!window.cbsoptanon || !measurementId) return;

    window.cbsoptanon.cmd.push((cmp: any) => {
      cmp.ot.performanceAllowed((allowed: boolean) => {
        setIsGaAllowed(allowed);
        if (!allowed) {
          // If GA is not allowed, clear the queue
          setGaQueue([]);
          return;
        }

        initializeGA4(measurementId);
      })
    });
  }, [measurementId])

  useEffect(function handleGaQueue() {
    if (!isGaAllowed || !gaQueue.length) return;

    gaQueue.forEach(({type, args}) => {
      switch (type) {
        case 'event':
          gaEvent(...args as GaEventParams);
          break;
        case 'page':
          gaPageView(...args as GaPageViewParams);
          break;
        default:
          // do nothing 
      }
    });
    setGaQueue([]);
  }, [isGaAllowed, gaQueue]);

  const trackGaEvent = useCallback((...args: GaEventParams) => {
    if (isGaAllowed === undefined) {
      setGaQueue((prev) => [...prev, {type: 'event', args}]);
    }

    if (!isGaAllowed) return;

    gaEvent(...args);
  }, [isGaAllowed]);

  const trackGaPageView = useCallback((...args: GaPageViewParams) => {
    if (isGaAllowed === undefined) {
      setGaQueue((prev) => [...prev, {type: 'page', args}]);
    };

    if (!isGaAllowed) return;

    gaPageView(...args);
  }, [isGaAllowed]);

  const trackOminitureEvent = useCallback(async ({...args}: OmnitureClickEventParams) => {
    await retry(() => omnitureClickEvent(args),
      { retries: RETRY_SETTINGS.MAX_ATTEMPTS, minTimeout: RETRY_SETTINGS.RETRY_DELAY }
    );
  }, []);

  const trackOminiturePage = useCallback(async ({...args}: OmniturePageEventParams) => {
    if (!shouldTrackOmniturePage) return;

    await retry(() => omniturePageEvent(args),
      { retries: RETRY_SETTINGS.MAX_ATTEMPTS, minTimeout: RETRY_SETTINGS.RETRY_DELAY }
    );
  }, []);

  const trackPageView = useCallback(({ title, name, url } : { title: string, name: string, url?: string }) => {
    trackGaPageView(title);

    const isClosedSnapshot = snapshotType === SnapshotType.CLOSED;
    if (isClosedSnapshot && shouldTrackOmniturePage) {
      setShouldTrackOmniturePage(false);
      trackOminiturePage({name: 'winner-page', url});
      return;
    }

    trackOminiturePage({name: name || pageName, url});
    
  }, [shouldTrackOmniturePage, snapshotType, pageName])

  const trackClickEvent = useCallback(({...args}: OmnitureClickEventParams) => {
    trackGaEvent('click', args.name, args.destination || '');
    trackOminitureEvent({section: pageName, ...args});
  }, [pageName])

  const value = useMemo(() => ({
    trackPageView,
    trackClickEvent,
    pageName,
    setPageName
  }), [
    trackPageView,
    trackClickEvent,
    pageName,
    setPageName
  ])

  return <TrackingContext.Provider value={value}>{children}</TrackingContext.Provider>
}

export const useTracking = () => {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }

  return context;
}