import { useDisclosure } from '@telescope/cassini-hooks';
import { UpsellBanner, UpsellModal } from '.';

export const Upsell = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <UpsellBanner onOpen={onOpen} />
      <UpsellModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}