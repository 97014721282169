import { useAuth } from "../providers";
import { ProviderId, ProviderType, AuthType, useSession } from "features";
import { useCallback, useEffect } from "react";
import { useParams, useNavigate, useLocation, useOutletContext } from "react-router-dom";
import { ROUTES } from "utils";

type CallbackHandlers = {
  handleSuccess: (data: Record<string, any>, state?: Record<string, any>) => void
  handleError: (error?: string, providerId?: ProviderId) => void
}

export const Callback = ({ authType } : { authType: AuthType }) => {
  const { handleSuccess, handleError } = useOutletContext<CallbackHandlers>();
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { getProviderById } = useAuth();
  const { login } = useSession();

  useEffect(() => {
    handleCallback();
  }, [params?.providerId])

  const handleCallback = async () => {
    const provider = getProviderById(params.providerId!)

    if (!provider) {
      return clearCallbackUrl(ROUTES.HOME);
    }

    let authBackgroundLocation = ROUTES.HOME;

    try {
      const { payload, state } = await provider.callback?.()

      if (state?.from) authBackgroundLocation = state?.from;
      navigate(location.pathname, { replace: true, state: { backgroundLocation: authBackgroundLocation }})
      
      const res = await login(payload) as Record<string, any>

      if (!res) {
        handleError();
        clearCallbackUrl(authBackgroundLocation);
        return;
      }

      if (provider.type !== ProviderType.IDENTITY) {
        handleSuccess({ ...payload, ...res }, state )
        clearCallbackUrl(authBackgroundLocation)
        return;
      }

      const { response_code, user_info, ...authPayload } = res;
      const { code, ...providerPayload } = payload;
      const userInfo = JSON.parse(user_info);

      handleSuccess({ ...providerPayload, ...authPayload, ...userInfo }, state);
      clearCallbackUrl(authBackgroundLocation)
    } catch (error: any) {
      handleError(error.message || error, provider.id);
      clearCallbackUrl(authBackgroundLocation)
    }
  }

  // Navigate back to auth page that triggered callback
  const clearCallbackUrl = useCallback((backgroundLocation: string) => {
    navigate('..', { state: { backgroundLocation }, replace: true })
  }, [authType])

  return null;
}