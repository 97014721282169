import { useCallback, useMemo, useState, useEffect } from 'react';
import { Container, SimpleGrid, Button, Grid, VStack, Flex, Spacer } from '@telescope/cassini-ui';
import { Heading, Text, Display } from 'components';
import { SocialButton, MultiVoteNominee, SingleVoteNominee, NomineeMedia, Confirmation, VideoPlayerModal } from '..';
import { ErrorPage, useVotingGrid, BidBarrelAd, useSession } from 'features';
import { Campaign, MultiVoteSnapshot, OpenSnapshot, SnapshotType, CategoryT, ErrorType } from 'types';
import { Sponsor, SPONSOR_WIDTH, replaceWildcardsWithProgress, useModals, Thanks } from 'features';
import { VoteAPIResponseCode } from '@telescope/cassini-hooks';
import { useWidget, useTracking } from 'providers';
import { VoteAction } from '../../reducers';
import { useNavigate, useParams } from 'react-router-dom';
import { categoryBg } from 'theme';

export type NomineeT = NonNullable<CategoryT<Campaign>['vote_choices']>[0];
type Nominees = {
  regular: NomineeT[]
  runnersUp: NomineeT[]
}

export const CategoryGrid = ({ categorySlug } : { categorySlug: string }) => {
  const { voteLimit, getCategoryBySlug, getCategoryTotal, 
      handleAddVotes, handleSubtractVotes, isCategorySubmitted,
      handleBatchSubmit, submittedCategories , votableCategories,
      getNextVotableCategorySlug, openSingleCategory, getCategoryPosition } = useVotingGrid();
  const category = getCategoryBySlug(categorySlug);

  const { data: votingData } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.vote })
  const { content } = votingData!;

  const { data: snapshotType } = useWidget({ select: (data: Campaign) => data.snapshot.type });

  const { nomineeSlug, categorySlug: slug } = useParams();
  const navigate = useNavigate();

  const { trackClickEvent } = useTracking();

  const { openModal, closeAll } = useModals();

  const isSubmitted = isCategorySubmitted(category.category_key);
  const isVotable = ("isVotable" in category) && category.isVotable;

  const { isAuthenticated } = useSession();
  const [confirmationTriggered, setConfirmationTriggered] = useState(false);

  const categoryTotal = useMemo(() => getCategoryTotal(category.category_key), [category.category_key, getCategoryTotal]);
  const hasVotesLeft = useMemo(() => !!Math.max(voteLimit - categoryTotal, 0), [categoryTotal, voteLimit]);
  
  // Split nominees between regular and runnersUp arrays
  const nominees = useMemo(() => {
    return (category.vote_choices as unknown as NomineeT[])?.reduce(
      (acc: Nominees, nominee: NomineeT) => {
      acc[(("isRunnerUp" in nominee) && nominee.isRunnerUp)? 'runnersUp' : 'regular'].push(nominee)
      return acc;
    }, {regular: [], runnersUp: []})
  }, [category.vote_choices])

  useEffect(function clearConfirmationTriggered() {
    if (!isAuthenticated) {
      setConfirmationTriggered(false);
    }
    
  }, [isAuthenticated])

  useEffect(function handleAllVotesAllocated() {
    if (!hasVotesLeft && !confirmationTriggered && !isSubmitted && isVotable) {
      setConfirmationTriggered(true);
      openModal({
        children: <Confirmation onConfirm={handleMultiVoteSubmit} onClose={closeAll}/>
      });
    }
  }, [hasVotesLeft, confirmationTriggered, isSubmitted])

  const handleVoteUpdate = useCallback((voteAction: VoteAction, nomineeId: string) => {
    const { category_key: categoryId } = category;
    switch(voteAction) {
      case VoteAction.ADD_VOTE:
        if (!hasVotesLeft) return;
        handleAddVotes(categoryId, nomineeId);
        break;
      case VoteAction.SUBTRACT_VOTE:
        handleSubtractVotes(categoryId, nomineeId);
        break;
      default:
        // do nothing
    }
  }, [handleAddVotes, handleSubtractVotes, hasVotesLeft])

  const goToNextVotableCategory = () => {
    const nextCategorySlug = getNextVotableCategorySlug(category.category_key);
    openSingleCategory(nextCategorySlug);
  }

  const handleMultiVoteSubmit = useCallback(async () => {
    trackClickEvent({
      name: `submit vote button_${categorySlug}`,
      section: categorySlug,
      url: window.location.href
    });

    try {
      await handleBatchSubmit(category.category_key);

      // If this was the last category, serve thanks modal
      if (submittedCategories.length + 1 === votableCategories.length) {
        openModal({
          children: <Thanks />
        });
        return;
      }

      closeAll();
      goToNextVotableCategory();
      
    } catch(error: any) {
      openModal({
        children: <ErrorPage 
          errorType={error?.message === VoteAPIResponseCode.OVERLIMIT? ErrorType.OVERLIMIT : ErrorType.VOTE} />
      });
    }
  }, [handleBatchSubmit, category.category_key, submittedCategories, votableCategories])

  const trackSocialButtonClick = useCallback(({copy, href}: {copy: string, href: string}) => {
    trackClickEvent({ name: `${copy.toLowerCase()}_${categorySlug}`, section: categorySlug, url: href, snapshotType });
  }, [snapshotType])

  return (
    <Container px={0} centerContent bg="CategoryGrid.bg" {...categoryBg}>
      <Display hide={!category.sponsor.image && !category.description}>
        <Grid templateColumns={{
            base: '1fr',
            md: `${SPONSOR_WIDTH}px auto ${SPONSOR_WIDTH}px`,
          }} 
          gap={{base: 5, md: 12}} px={6} pt={6} w="full" maxW="78rem">
          <Spacer />

          { category.description? 
            <Text fontSize={{base: 'sm', md: 'md'}} color="CategoryGrid.text" textAlign="center" maxW="600px" m="auto">{category.description}</Text> :
            <Spacer /> }
          
          <Sponsor colorMode="light" {...category.sponsor} />
          
        </Grid>
      </Display>

      <Flex w="full" bg="transparent" px={6} py={12} alignItems="center" direction="column">
        <Flex _empty={{display: 'none'}} wrap="wrap" justifyContent="center" mb={8} gap={6}>
          {(category as CategoryT<OpenSnapshot>).socialButtons?.map((button) => 
            <SocialButton {...button} onClick={() => trackSocialButtonClick({...button})} key={button.type} />)}
        </Flex>

        <SimpleGrid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} 
                    spacing={6} maxW="75rem" w="100%" py={6}>
          {nominees.regular?.map((data, i) => {
            if (!isVotable) {
              return <NomineeMedia {...data} categorySlug={categorySlug} key={data.schema_reference}/>
            }
        
            switch(snapshotType) {
              case SnapshotType.MULTI_VOTE:
                return <MultiVoteNominee categorySlug={categorySlug} data={data} 
                  handleVoteUpdate={handleVoteUpdate} key={data.schema_reference} 
                  isDisabled={isSubmitted} isPlusEnabled={hasVotesLeft || !confirmationTriggered} />
              case SnapshotType.SINGLE_VOTE:
                return <SingleVoteNominee categorySlug={categorySlug} data={data} hasVotesLeft={hasVotesLeft} key={data.schema_reference} />
              default:
                return (
                  <NomineeMedia {...data} categorySlug={categorySlug} key={data.schema_reference} />
                )
            }
          })}

          <BidBarrelAd categoryPosition={getCategoryPosition(categorySlug)} sponsor={category.ad?.tag} />
        </SimpleGrid>
        
        <Display hide={!nominees.runnersUp.length}>
          <Heading color="CategoryGrid.text">{content.runnersUpHeadline}</Heading>
          <SimpleGrid templateColumns={{base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'}} 
                      spacing={6} maxW="75rem" w="100%" py={6}>
            {nominees.runnersUp?.map((nominee, i) => 
              <NomineeMedia {...nominee} categorySlug={categorySlug} key={nominee.schema_reference} />)}
          </SimpleGrid>
        </Display>

        <Display hide={(snapshotType !== SnapshotType.MULTI_VOTE) || !isVotable || isSubmitted}>
          <VStack spacing={6} py={8}>
            <Text fontWeight={800} fontSize="lg" color="CategoryGrid.text">
              {replaceWildcardsWithProgress(getCategoryTotal(category.category_key), voteLimit, content.progress?.category)}
            </Text>
            <Button fontSize="sm" h="auto" pb={3} pt={4} 
              isDisabled={isSubmitted || !getCategoryTotal(category.category_key)} onClick={handleMultiVoteSubmit}>
              {content.voteButton}
            </Button>
          </VStack>
        </Display>

      </Flex>

      <Display hide={!nomineeSlug || categorySlug !== slug}>
        <VideoPlayerModal 
          key={categorySlug}
          onClose={() => navigate(`/${categorySlug}`)} />
      </Display>
    </Container>
  )
}