import { Container } from '@chakra-ui/react';
import { Text } from 'components';
import { useWidget } from 'providers';
import { MultiVoteSnapshot } from 'types';
import { replaceWildcardsWithProgress, useVotingGrid } from 'features/vote';

export const ProgressBar = () => {
  const { data: progressBar } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.progressBar });

  const { submittedCategories, votableCategories } = useVotingGrid();

  if (!progressBar?.settings.isEnabled) return null;

  return (
    <Container centerContent={true} bg="ProgressBar.bg" py={{ base: 5, md: 6 }} position="sticky" bottom={0}>
      <Text fontSize="md" color="ProgressBar.text" fontWeight={800}>
        {replaceWildcardsWithProgress(submittedCategories.length, votableCategories.length, progressBar?.copy?.text)}
      </Text>
    </Container>
  )
}