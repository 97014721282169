import { Box } from '@telescope/cassini-ui';
import { useEffect, useMemo } from 'react';
import { createBidBarrelAd, removeBidBarrelAd } from './lib';
import { isMobileOnly } from 'react-device-detect';
import { useTracking } from 'providers';

type BidBarrelAdProps = {
  sponsor?: string;
} & ({ adId: string } | { categoryPosition: number });

export const BidBarrelAd = (props: BidBarrelAdProps) => {
  const { trackClickEvent } = useTracking();

  const { config, id } = useMemo(() => {
    if ('adId' in props) return { config: props.adId, id: props.adId };

    const categoryPosition = 'categoryPosition' in props? props.categoryPosition : 0;
    const adType = isMobileOnly ? "mobile_mpu_inc" : "mpu_inc";
    const adId = `${adType}-${categoryPosition}`;
    return { config: [adType, categoryPosition, {pos: "inc"}], id: adId }
  }, [props])

  useEffect(() => {
    createBidBarrelAd(config, props.sponsor);

    return () => {
      removeBidBarrelAd(id);
    }
  }, [id]);

  return (
    <Box maxW="full" w="full" overflow="hidden" position="relative" sx={{
      '#skybox_leader_plus_sticky': {
        textAlign: 'center',
        width: '100%'
      },
      '#mobile_skybox_plus_sticky': {
        textAlign: 'center',
        width: '100%'
      },
      '#skybox_leader_plus_sticky iframe[id^=google_ads_iframe]': {
        position: 'relative !important',
        top: 'unset !important',
        margin: '0 auto',
      },
      '~ #header.skybox-fixed': {
        position: 'relative !important',
        top: 'unset !important',
        marginTop: '0 !important',
      },
      '[id^=google_ads_iframe]': {
        margin: '0 auto',
      }
    }}>
      <Box id={id} minH={id.includes('mpu_inc')? '250px' : 'none'} 
        onClick={() => trackClickEvent({ name: `ad_${id}` })}/>
    </Box>
  )
}