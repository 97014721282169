
import { isMobileOnly } from 'react-device-detect';

/**
 * MTV provides pagePath. Sometimes it is provided in the following format:
 * 8264,aw-cmt,cmt-music-awards,voting
 * 
 * In this case, use the third and fourth values in the comma separated list to create the pagePath.
 * e.g. cmt-music-awards/voting
*/

const config = {
  pagePath: 'cmt-music-awards/voting',
  ptype: 'vote',
  franchise: 'cmt_music_awards',
  event: 'cmt_music_awards_2024'
}

export const initializeBidBarrel = () => {
  window.BidBarrel?.initialize({
    dfpPath: {
      device: isMobileOnly ? 'm' : '',
      pagePath: config.pagePath
    },

    targeting: {
      auto: true,
      cookie: {
          surround: true,
          consolidate: true
      }
    },

    pageTargeting: {
      ptype: config.ptype,
      env: 'prod', 
      franchise: config.franchise,
      event: config.event
    }
  });
}

export const createBidBarrelAd = (config: string | any[], sponsor?: string): void => {
  try {
    if (!window.cbsoptanon || !window.cbsoptanon.onAdsReady) return;

    window.cbsoptanon.onAdsReady(() => {
      if (!window.BidBarrel) return;

      window.BidBarrel.auction([config]);
      
      if (sponsor) {
        window.BidBarrel.setTargeting({
          sponsor: sponsor
        }, [config])
      }
    });
  } catch(err) {
    console.error(err);
  }
}

export const removeBidBarrelAd = (adIds: string | string[]) => {
  if (!window.BidBarrel || !window.BidBarrel.destroySlots) return;

  window.BidBarrel.destroySlots(Array.isArray(adIds)? adIds : [adIds]);
}