import { Container, VStack } from '@telescope/cassini-ui';
import { useWidget } from 'providers';
import { Text } from 'components';
import { MultiVoteSnapshot } from 'types';
import { useTurboVote } from 'features/vote';

export const TurboBanner = () => {
  const { data: turbo } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.turbo });
  const { copy } = turbo!;

  const { isTurbo } = useTurboVote();

  if (!isTurbo) {
    return null;
  }

  return (
    <Container as={VStack} py={[7, 7, 10]} textAlign="center" spacing={3} bg="TurboBanner.bg" color="TurboBanner.text">
      <Text fontWeight={800} fontSize="2xl">{copy.headline}</Text>
      <Text fontSize="sm">{copy.description}</Text>
    </Container>
  )
}