import { FormControlProps } from '@chakra-ui/form-control';
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input } from '@telescope/cassini-ui';
import { forwardRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { PatternFormat, PatternFormatProps } from 'react-number-format';

type PhoneNumberFieldProps = UseControllerProps &
  FormControlProps &
  Omit<PatternFormatProps, 'customInput'> & { helpText?: string };

export const PhoneNumberField = forwardRef<HTMLSelectElement, PhoneNumberFieldProps>(
  (
    {
      isRequired,
      label,
      helpText,
      isDisabled,
      isReadOnly,
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
      ...inputProps
    },
    ref
  ) => {
    const {
      field: { onChange, onBlur, value },
      fieldState: { error },
    } = useController({
      name,
      rules,
      defaultValue,
      shouldUnregister,
    });

    return (
      <FormControl  isInvalid={!!error} isRequired={isRequired} isDisabled={isDisabled} isReadOnly={isReadOnly}>
        <FormLabel>{label}</FormLabel>

        <PatternFormat
          customInput={Input}
          type="tel"
          autoComplete="on"
          onBlur={onBlur}
          onValueChange={({ value }) => onChange(value)}
          {...inputProps}
        />

        {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
);
