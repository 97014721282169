import { 
  Box, VStack, IconButton, HStack,
  Drawer, DrawerContent, DrawerBody
} from '@telescope/cassini-ui';
import { useDisclosure } from '@telescope/cassini-hooks';
import { Link } from 'components';
import { useMtvData } from 'hooks';
import { IoMdMenu, IoMdClose } from 'react-icons/io';
import { useEffect } from 'react';
import { LogoSm } from './logos';
import { HeaderLink } from './HeaderLink';
import { useScroll } from 'providers';

export const MobileHeader = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { stickyRef, setStickyRef, scrollToHeader } = useScroll();

  useEffect(() => {
    if (!isOpen) return;
    scrollToHeader();
  }, [isOpen, stickyRef])


  const data = useMtvData('MTVNavigation', 'MTVNavigationLoaded');
  if (!data) return null;

  return (
    <>
      <HStack as="header" bg="Header.bg" w="full" px={4} py={1} justifyContent="space-between" 
              position="sticky" top={0} zIndex={isOpen? 2000 : 'sticky'} ref={setStickyRef}>
        <IconButton icon={isOpen? <IoMdClose color="inherit" size={30}/> : <IoMdMenu color="inherit" size={30}/>} 
                    color="white"
                    aria-label='Open navigation' onClick={onToggle} variant="unstyled"/>
        <Link href={window.mtvHeaderUrl}><LogoSm /></Link>
        <Box w={10} />
      </HStack>

      <Drawer onClose={onClose} isOpen={isOpen} size="full" placement="left">
        <DrawerContent pt={`${stickyRef?.offsetHeight}px`}>
          <DrawerBody bg="Header.bg" px={4}>
            <VStack spacing={2}>
              {data.navigationEntries?.map((link: { title: string, url: string, id: string }) => 
                <HeaderLink href={link.url} copy={link.title} key={link.id} fontSize="md" w="100%" />)}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}