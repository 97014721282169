import { Button, Grid, VStack } from '@telescope/cassini-ui';
import { Heading } from 'components';
import { MultiVoteSnapshot } from 'types';
import { useWidget } from 'providers';
import { useVotingGrid } from '../providers';
import { useState } from 'react';

export const Confirmation = ({ onConfirm, onClose } : { onConfirm: () => void, onClose: () => void }) => {
  const { data } = useWidget({ select: (data: MultiVoteSnapshot) => data.snapshot.snapshot_views.vote.content.confirmation });
  const { headline, buttons } = data!;

  const { voteLimit } = useVotingGrid();

  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmationClick = () => {
    setIsLoading(true);
    onConfirm();
  }

  return (
    <VStack spacing={12}>
      <Heading fontSize="2xl">
        {headline.replace('{{VOTE_LIMIT}}', `${voteLimit}`)}
      </Heading>
      <Grid gap={6} w="full" templateColumns="1fr 1fr">
        <Button onClick={handleConfirmationClick} isLoading={isLoading} loadingText={buttons.submit}>
          {buttons.submit}
        </Button>
        <Button onClick={onClose} isDisabled={isLoading}
          bg="Button.secondary.default.bg" color="Button.secondary.default.text"
          _hover={{bg: "Button.secondary.hover.bg", color: "Button.secondary.hover.text"}}>
          {buttons.edit}
        </Button>
      </Grid>
    </VStack>
  )
}