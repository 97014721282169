import { FormControlProps } from '@chakra-ui/form-control';
import { InputProps } from '@chakra-ui/input';
import { useMergeRefs } from '@telescope/cassini-hooks';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@telescope/cassini-ui';
import { forwardRef, useRef } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import { FaRegCalendarAlt } from 'react-icons/fa';

type DatePickerFieldProps = UseControllerProps & InputProps & FormControlProps & { helpText?: string };

export const DatePickerField = forwardRef<HTMLInputElement, DatePickerFieldProps>(
  (
    {
      isRequired,
      label,
      helpText,
      isDisabled,
      isReadOnly,
      name,
      rules,
      shouldUnregister,
      defaultValue,
      control,
      ...inputProps
    },
    ref
  ) => {
    const { field, fieldState } = useController({ name, rules, defaultValue });
    const { ref: controllerRef, ...useControllerProps } = field;
    const inputRef = useRef<HTMLInputElement>(null);
    const refs = useMergeRefs(inputRef, controllerRef, ref);

    return (
      <FormControl
        isInvalid={!!fieldState.error}
        isRequired={isRequired}
        isDisabled={isDisabled}
        isReadOnly={isReadOnly}>
        <FormLabel>{label}</FormLabel>
        <InputGroup onClick={(e) => inputRef.current?.showPicker()}>
          <InputRightElement zIndex="base" children={<FaRegCalendarAlt />} />
          <Input
            sx={{ '::-webkit-calendar-picker-indicator': { display: 'none' } }}
            {...inputProps}
            {...useControllerProps}
            ref={refs}
          />
        </InputGroup>
        {!!fieldState.error && <FormErrorMessage>{fieldState.error?.message}</FormErrorMessage>}
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
      </FormControl>
    );
  }
);
