export function gtag(...args: any[]) { 
  window.dataLayer.push(arguments); 
}

export const initializeGA4 = (measurementId: string) => {
  var script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
  document.head.appendChild( script );

  window.dataLayer = window.dataLayer || [];
  
  gtag('js', new Date());
  gtag('config', measurementId);
}

export type GaPageViewParams = Parameters<(pageTitle: string) => void>
export const gaPageView = (...[pageTitle]: GaPageViewParams) => {
  gtag('event', 'page_view', {
    page_title: pageTitle,
    page_location: window.location.href
  });
}

export type GaEventParams = Parameters<(action: string, label: string, category: string) => void>
export const gaEvent = (...[action, label, category]: GaEventParams) => {
  gtag('event', action, {
    event_label: label,
    event_category: category
  });
}