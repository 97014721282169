import { defineStyle, defineStyleConfig } from "@chakra-ui/styled-system"

const baseStyle = defineStyle({
  fontSize: "md",
  fontWeight: "600"
})

export const formLabelTheme = defineStyleConfig({
  baseStyle,
})
