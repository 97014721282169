import { StyleFunctionProps } from '@telescope/cassini-ui';
import { defineStyle } from '@chakra-ui/styled-system';

const variantSolid = defineStyle((props) => {
  return {
    field: {
      bg: 'Form.input.bg',
      color: 'Form.input.text',
      fontSize: 'md',
      _hover: {
        bg: 'Form.input.bg',
        _disabled: {
          bg: 'initial',
        },
      },
      _active: { 
        bg: 'Form.input.bg'
      },
      _focusVisible: { borderColor: 'focus', boxShadow: '0 0 0 1px var(--cassini-colors-focus)' },
      _invalid: {
        borderColor: 'Form.error',
        boxShadow: '0 0 0 1px var(--cassini-colors-Form-error)',
      }
    }    
  };
});

export const inputTheme = {
  baseStyle: {
    field: {
      borderRadius: 0,
      fontSize: 'sm',
      fontFamily: 'Form.input'
    }
  },
  variants: {
    outline: (props: StyleFunctionProps) => {
      return ({
        field: {
          _focusVisible: { borderColor: 'focus', boxShadow: '0 0 0 1px var(--cassini-colors-focus)' },
          _invalid: {
            borderColor: 'Form.error',
            boxShadow: '0 0 0 1px var(--cassini-colors-Form-error)',
          }
        },
      })
    },
    solid: variantSolid
  },
  defaultProps: {
    variant: 'solid'
  }
};