import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { AuthLayout, Login, Callback } from '../components';

export const AuthRoutes = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="auth/*" element={<AuthLayout />}>
        <Route index element={<Navigate to="login" state={location.state} />} />

        <Route path='login' element={<Login />}>
          <Route path='callback/:providerId' element={<Callback authType="login" />} />
        </Route>

        <Route path='*' element={<Navigate to="login" state={location.state} />} />
      </Route>
    </Routes>
  )
}