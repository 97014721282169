import React, { createContext, useContext, useMemo, useCallback } from 'react';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseMutateAsyncFunction
} from '@tanstack/react-query';
import { useStorage } from 'providers';
import { AUTH_LOCALSTORAGE_KEY } from 'features'; 
import { useWidget } from 'providers';
import { Campaign } from 'types';
import { Session as SessionT } from 'features/auth/types';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'utils';

type SessionProps = {
  children: React.ReactNode
}

// @TODO: fix login, register and update types
type SessionContextValue<Session = SessionT, Error = unknown> = {
  session: Session | undefined
  error: Error | null
  isLoading: boolean
  login: UseMutateAsyncFunction<Session, any, any>
  logout: UseMutateAsyncFunction<any, any, void, any>
  handleSuccess: (data: Record<string, any>) => void
  isAuthenticated: boolean
  validateSession: () => boolean
}

export interface SessionProviderConfig<Error = unknown> {
  key?: string;
  ErrorComponent?: ({ error }: { error: Error | null }) => JSX.Element;
}

export function initSession<Session = SessionT, Error = unknown>(config: SessionProviderConfig<Error> = {}) {
  const SessionContext = createContext<SessionContextValue<Session, Error> | null>(null);
  SessionContext.displayName = 'SessionContext';

  const {
    key = 'auth-user',
    ErrorComponent = (error: any) => <div style={{ color: 'tomato' }}>{JSON.stringify(error, null, 2)}</div>,
  } = config;

  const SessionProvider = ({ children }: SessionProps) => {
    const queryClient = useQueryClient();

    const storage = useStorage();

    const { data: widget } = useWidget({ select: (data: Campaign) => data });
    const { sid } = widget!

    const navigate = useNavigate();
    const location = useLocation();

    const setSession = useCallback((data: Session) => {
      queryClient.setQueryData([key, { sid }], data);
      storage.setItem(AUTH_LOCALSTORAGE_KEY, data);
      return data;
    },[queryClient, storage, sid]);

    const loadSession = async (): Promise<Session> => {
      return storage.getItem(AUTH_LOCALSTORAGE_KEY);
    };

    const {
      data: session,
      error
    } = useQuery<Session, Error>({
      queryKey: [key, { sid }],
      queryFn: loadSession,
    });

    const isAuthenticated = useMemo(() => !!(session as SessionT)?.user?.user_id, [session])
    
    const promptLogin = useCallback(() => {
      return navigate(ROUTES.LOGIN, { state: { backgroundLocation: location.pathname } })
    }, [location.pathname, navigate])

    const validateSession = useCallback(() => {
      if (!isAuthenticated) {
        promptLogin();
        return false;
      }

      return true;
    }, [isAuthenticated, promptLogin]);

    const { mutateAsync: login, isLoading: isLoginLoading } = useMutation(
      async (data: any) => {
      return new Promise((resolve, reject) => {
        if (data.user_id) resolve(data);
        else reject(new Error('Invalid user'));
      }) as Session;
    });

    // @TODO: fix any types
    const handleSuccess = useCallback((data: Record<string, any>) => {
      const { response_code, ...userData } = data;
      setSession({ user: userData } as Session)
    }, [setSession]);

    const { mutateAsync: logout } = useMutation({
      mutationFn: async () => storage.removeItem(AUTH_LOCALSTORAGE_KEY),
      onSuccess: () => {
        queryClient.resetQueries([key]);
      },
    });

    const value = useMemo(() => ({
      session,
      error,
      isLoading: isLoginLoading,
      login,
      logout,
      handleSuccess,
      validateSession,
      isAuthenticated,
    }), [
      session,
      error,
      login,
      logout,
      handleSuccess,
      validateSession,
      isAuthenticated,
      isLoginLoading
    ])

    if (error) {
      return <ErrorComponent error={error} />;
    }

    return (
      <SessionContext.Provider value={value}>
        { children }
      </SessionContext.Provider>
    )
  }

  const useSession = () => {
    const context = useContext(SessionContext);
  
    if (!context) {
      throw Error('Must be used inside SessionProvider')
    }
  
    return context;
  }

  return { SessionProvider, useSession }
}