import { ProviderId } from '../providers';
import { Button, ButtonProps } from '@telescope/cassini-ui';
import { Google as GoogleIcon } from 'components';

type AuthButtonProps = ButtonProps & {
  providerId: ProviderId
}

const baseStyle = {
  fontSize: 'sm'
}

export const AuthButton = ({ providerId, children, ...props } : AuthButtonProps) => {
  switch (providerId) {
    case ProviderId.GOOGLE:
      return (
        <Button leftIcon={<GoogleIcon />} {...baseStyle}
                bg="Form.google.default.bg" color="Form.google.default.text"
                _hover={{'@media (hover: hover) and (pointer: fine)': {
                  bg: 'Form.google.hover.bg', color: 'Form.google.hover.text'
                }}} 
                {...props}>
          {children}
        </Button>)
    default:
      return <Button {...baseStyle} {...props}>{children}</Button>
  }
}