import { useEffect, useState, useMemo } from 'react';
import { HStack, VStack } from '@telescope/cassini-ui';
import { Text } from 'components';
import { useCountdown, TimeUnit } from '@telescope/cassini-hooks';
import { TimeStat, TimeStatLabel, TimeStatValue } from 'components';
import { UnitLabel, getDailyResetTime } from './utils';
import { useWidget } from 'providers';
import { OpenSnapshot } from 'types';
import { isPast, addDays } from 'date-fns';

export const Countdown = () => {
  const { data: countdownData } = useWidget({ select: (data: OpenSnapshot) => data.snapshot.snapshot_views.countdown });
  const { settings } = countdownData!;

  if (!settings.isEnabled) return null;

  return <CountdownClock />
}

export const CountdownClock = () => {
  const { data: countdownData } = useWidget({ select: (data: OpenSnapshot) => data.snapshot.snapshot_views.countdown });
  const { settings, content } = countdownData!;
  const units = settings.units as Record<TimeUnit, boolean>;

  const getTarget = () => {
    return (isPast(endDate) || !settings.shouldResetDaily)? 
    endDate : getDailyResetTime(endDate);
  }

  const endDate = useMemo(() => new Date(settings.target), [settings.target]);
  const [target, setTarget] = useState(getTarget());
  const countdown = useCountdown(target, { units }); 

  useEffect(() => {
    setTarget(getTarget());
  }, [endDate])

  useEffect(() => {
    if (!settings.shouldResetDaily || isPast(endDate) || !isPast(target)) return;

    setTarget(addDays(target, 1));
  }, [endDate, target, countdown.totalRemaining]);

  if (!target) return null;

  return (
    <VStack textAlign="center" spacing={4} bg="Countdown.bg" color="Countdown.text">
      <Text bg="Countdown.label.bg" color="Countdown.label.text" fontSize="xs" fontWeight={800} px={2} pt="7px" pb="4px">
        {content.label}
      </Text>

      <HStack justifyContent="center">
        {(Object.keys(units) as TimeUnit[])
          .filter((key) => units[key])
          .map((key, i) => {
            const unit = key as keyof typeof UnitLabel;
            const value = `0${countdown[unit]}`.slice(-2);
            const label = UnitLabel[unit];
            return (
              <TimeStat key={key} minW="2.15rem">
                <TimeStatValue fontFamily="Countdown.number"> 
                  { !!i && <Text position="absolute" fontSize="2xl" lineHeight="none" left={-2}>:</Text> }
                  {value} 
                </TimeStatValue>
                <TimeStatLabel fontFamily="Countdown.label"> {label} </TimeStatLabel>
              </TimeStat>
            );
          })}
      </HStack>

      <Text allowMarkdown fontSize="sm" mt={2}>
        {content.description}
      </Text>
    </VStack>
  );
};
