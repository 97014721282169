
import React, { createContext, useContext, useMemo } from 'react';
import { useVoteApi } from '@telescope/cassini-hooks';
import { useWidget } from 'providers';
import { RULESET_SETTINGS, getAppConfig } from 'utils';
import { SnapshotType, Campaign } from 'types';
import { useQuery } from '@tanstack/react-query';
import { isTruthy } from '@telescope/cassini-utilities';

type TurboVoteProviderProps = {
  children: React.ReactNode
};

type TurboVoteContextValue = {
  isTurbo: boolean
}

const { isDevMode } = getAppConfig();

const TurboVoteContext = createContext<TurboVoteContextValue | undefined>(undefined);

export function TurboVoteProvider({ children } : TurboVoteProviderProps) {
  const { data: snapshotType } = useWidget({
    select: (data: Campaign) => data.snapshot.type
  });
  const { data: ruleset } = useWidget({ select: (data: Campaign) => data.widget.settings.campaignSettings.ruleset });

  const turboApi = useVoteApi({
    apiKey: ruleset?.turboVote.apiKey,
    versionId: `${ruleset?.turboVote.versionId}${RULESET_SETTINGS.TURBO_VOTE_VERSION_CHECK}`,
  }, { authType: 2 });

  const {
    data: isTurbo
  } = useQuery({ 
    queryKey: ['turbo'], 
    queryFn: async () => {
      const { turbo_window = 'false' } = await turboApi();
      return isTruthy(turbo_window);
    },
    enabled: snapshotType !== SnapshotType.CLOSED && !isDevMode,
    staleTime: Infinity,
    refetchInterval: isDevMode? Infinity : 10000
  })

  const value = useMemo(() => ({
    isTurbo: !!isTurbo
  }), [
    isTurbo
  ])

  return <TurboVoteContext.Provider value={value}>{ children }</TurboVoteContext.Provider>
}

export const useTurboVote = () => {
  const context = useContext(TurboVoteContext);

  if (!context) {
    throw new Error('useTurboVote must be used within a TurboVoteProvider');
  }

  return context;
}