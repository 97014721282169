import { Link } from 'components';
import { LinkProps } from '@chakra-ui/react';
import { useTracking } from 'providers';

type HeaderLinkProps = Omit<LinkProps, 'children'> & { copy: string };

export const HeaderLink = ({ href, copy, ...props } : HeaderLinkProps) => {

  const { trackClickEvent } = useTracking();

  const styles = {
    opacity: 0.8,

    ':after': {
      background: '#f3f3f3',
      bottom: 0,
      content: '""',
      display: 'block',
      height: '2px',
      left: '51%',
      position: 'absolute',
      right: '51%',
      transitionDuration: '0.01s',
      transitionProperty: 'left,right,width',
      transitionTimingFunction: 'ease',
      width: 0
    }
  }

  const hover = {
    opacity: 1,

    ':after': {
      left: 0,
      right: 0,
      transitionDuration: '0.2s',
      width: '100%'
    }
  }

  if (!href) return null;

  const isExternal = /https?:\/\//.test(href);

  return (
    <Link href={isExternal? href : `${window.mtvNavigationBaseUrl}${href}`} variant="unstyled" px={2} py={6}
          target="_blank"
          color="#fff"
          textTransform="uppercase" 
          position="relative"
          fontSize="xs"
          fontFamily="Header.link"
          sx={styles}
          _hover={hover}
          onClick={() => trackClickEvent({name: `header|${copy}`, destination: href, modname: 'header'})}
          {...props}>
        {copy}
      </Link>
  )
}