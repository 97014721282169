import { Link as CassiniLink } from '@telescope/cassini-ui';
import React, { FC, useCallback } from 'react';
import { Markdown, MarkdownProps } from 'components';
import { LinkProps as ChakraLinkProps } from '@chakra-ui/react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

interface MarkdownLinkProps extends ChakraLinkProps {
  /**
   * if true, text is interpreted as Markdown
   */
  allowMarkdown: true;
  /**
   * Options for Markdown parser
   */
  markdownOptions?: MarkdownProps;
  /**
   * If allowMarkdown is true, children must be a string
   */
  children?: string;
}

interface PlainTextLinkProps extends ChakraLinkProps {
  /**
   * if false, text is rendered as plain text
   */
  allowMarkdown?: false;
  children?: React.ReactNode;
}

type LinkProps = (PlainTextLinkProps | MarkdownLinkProps) & Partial<Pick<RouterLinkProps, 'to'>>;

/**
 * An enhanced Link component that determines if the supplied href is an internal link, and if so, use the Router, otherwise just render it as a normal anchor tag. Also allows the supplied text to be Markdown
 */
export const Link: FC<LinkProps> = (props) => {
  const { href = '', children, allowMarkdown, ...restProps } = props;

  const renderLinkContent = useCallback(() => {
    if (!children) return null;
    return allowMarkdown ? <Markdown children={children} {...props.markdownOptions} /> : children
  }, [allowMarkdown, children])

  if (!href || !children) return null;

  const isExternal = /https?:\/\//.test(href);

  if (isExternal) {
    return (
      <CassiniLink href={href} target="_blank" {...restProps}>
        {renderLinkContent()}
      </CassiniLink>
    );
  }

  return (
    <CassiniLink as={RouterLink} to={props.to ?? href} {...restProps}>
      {renderLinkContent()}
    </CassiniLink>
  );
};