import { Flex, Container } from '@telescope/cassini-ui';

export const HeroImage = ({ src, children } : { src: string, children?: React.ReactNode }) => {
  return (
    <Flex backgroundImage={`linear-gradient(180deg, rgba(0, 0, 0, 0), #000000), url(${src})`} 
          bgPosition={{base: 'center', md: 'top center'}} bgSize="cover"
          alignItems="flex-end" justifyContent="center"
          h={{base: '24rem', md: '31rem'}} w="100%">
      <Container py={[8, 8, 12]}>
      {children}
      </Container>
    </Flex>
  )
}