import React, { FC } from 'react';
import { Text as CassiniText } from '@telescope/cassini-ui';
import { Markdown, MarkdownProps } from 'components';
import { TextProps as ChakraTextProps } from '@chakra-ui/react';

interface MarkdownTextProps extends ChakraTextProps {
  /**
   * if true, text is interpreted as Markdown
   */
  allowMarkdown: true;
  /**
   * Options for Markdown parser
   */
  markdownOptions?: MarkdownProps;
  /**
   * If allowMarkdown is true, children must be a string
   */
  children?: string;
}

interface PlainTextProps extends ChakraTextProps {
  /**
   * if false, text is rendered as plain text
   */
  allowMarkdown?: false;
  children?: React.ReactNode;
}

type TextProps = PlainTextProps | MarkdownTextProps;

export const Text: FC<TextProps> = (props) => {
  const { children, allowMarkdown, ...restProps } = props;

  if (!children) return null;

  return (
    <CassiniText {...restProps}>
      {allowMarkdown ? <Markdown children={children} {...props.markdownOptions} /> : children}
    </CassiniText>
  );
};