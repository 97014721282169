import { useWidget } from 'providers';
import {
  Button,
  VStack
} from '@telescope/cassini-ui';
import { Heading, Text, Link } from 'components';
import { ErrorType } from 'types'; 
import { Campaign } from 'types';

interface ErrorModalProps {
  /**
   * If provided, used as key to for looking up error copy in widget. If omitted, the page will default to generic
   */
  errorType?: ErrorType;
  /**
   * Additional content to be displayed
   */
  children?: React.ReactNode;
}

export const ErrorPage = ({ children, errorType }: ErrorModalProps) => {
  const error = (errorType ?? ErrorType.GENERIC) as ErrorType;
  const { data } = useWidget({
    select: (data: Campaign) => data.widget.global_views.errors.content[error],
  });
  const { copy, link } = data!;


  return (
    <VStack textAlign="center" spacing={6}>
      <Heading as="h1" fontSize="2xl">
        {copy.headline}
      </Heading>
      <Text allowMarkdown fontSize="sm">
        {copy.description}
      </Text>
      <Button as={Link} href={link.url} w="full" pb={3} pt={4}>
        {link.copy}
      </Button>
      {children}
    </VStack>
  );
};
