import { Show, Hide } from '@telescope/cassini-ui';
import { DesktopHeader } from './DesktopHeader';
import { MobileHeader } from './MobileHeader';

export const Header = () => {
  return (
    <>
      <Show above='lg'>
        <DesktopHeader />
      </Show>
      <Hide above='lg'>
        <MobileHeader />
      </Hide>
    </>
  )
}