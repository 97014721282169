import { Button, Link, Show } from '@telescope/cassini-ui';
import { Text } from 'components';
import { useMtvData } from 'hooks';

export const UpsellBanner = ({ onOpen } : { onOpen: () => void }) => {
  const data = useMtvData('MTVUpsellBanner', 'MTVUpsellBannerLoaded');
  if (!data) return null;

  const isButton = !!data.parameters.upSellExit;

  return (
    <Button width="full" py={2} px={3} h="auto" fontFamily="UpsellBanner.text"
      bg="UpsellBanner.bg" color="UpsellBanner.text" _hover={{bg: 'UpsellBanner.bg'}}
      {...(isButton? { onClick: onOpen } : { as: Link, href: data.promoResourceLinks[0].url })}>
      <Show above="md">
        <Text fontWeight="400" mr={4}>{data.copy}</Text>
      </Show>
      
      <Text fontSize="sm" fontFamily="UpsellBanner.button" 
            border="2px solid currentColor" rounded={4} py={2} px={3} lineHeight="none">
        {data.promoResourceLinks[0].copy}
      </Text>
    </Button>
  )
}