import { cx } from '@chakra-ui/shared-utils';
import { HTMLChakraProps, chakra, forwardRef } from '@chakra-ui/system';
import { useTimeStatStyles } from './TimeStat';

export interface TimeStatNumberProps extends HTMLChakraProps<'dd'> {}

export const TimeStatValue = forwardRef<TimeStatNumberProps, 'dd'>(function TimeStatValue(props, ref) {
  const styles = useTimeStatStyles();
  return (
    <chakra.dd
      ref={ref}
      {...props}
      className={cx('cassini-time-stat__value', props.className)}
      __css={{
        ...styles.value,
        fontFeatureSettings: 'pnum',
        fontVariantNumeric: 'proportional-nums',
      }}
    />
  );
});

TimeStatValue.displayName = 'TimeStatValue';
