import { ModalProps, ModalBodyProps } from '@chakra-ui/modal';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '@telescope/cassini-ui';
interface ContentModalProps extends ModalProps {
  bodyProps?: ModalBodyProps
}

export const ContentModal = ({ bodyProps, children, ...props }: ContentModalProps) => {
  return (
    <Modal {...props} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent w="full" maxW="500px">
        <ModalCloseButton />
        <ModalBody px={[7, 7, 10]} py={[7, 7, 14]} bg="Modal.bg" color="Modal.text" {...bodyProps}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};