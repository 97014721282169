import { ProviderId, ProviderType } from '../../types';

export const EmailAuthError = {
  NO_EMAIL: 'no_email'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type EmailAuthError = typeof EmailAuthError[keyof typeof EmailAuthError];

export const Email = () => {

  const authenticate = (data: Record<string, any>) => {
    if (!data.user_email && !data.email) {
      // Make email error msg dynamic
      throw Error(EmailAuthError.NO_EMAIL);
    }

    return (
      { method: 'email', user_id: data.user_email || data.email, ...data }
    )
  }

  return {
    id: ProviderId.EMAIL,
    type: ProviderType.SYNCHRONOUS,
    authenticate
  }
}

