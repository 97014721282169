import { Image } from '@telescope/cassini-ui';
import { useWidget } from 'providers';
import { Campaign } from 'types';
import { useImageSize } from 'react-image-size';

export const LogoLg = () => {
  const { data: header } = useWidget({ select: (data: Campaign) => data.widget.global_views.header });
  const { content: { logo: { lg: logo }}} = header!;

  const [dimensions] = useImageSize(logo.src || '');
  if (!dimensions?.width) return null;

  return (
    <>
      <Image alt="" maxW="100%" m="auto" w={dimensions.width / 2} {...logo} />
    </>
  )
}