import { MultiVote, SingleVote, Closed } from 'generated-types/widget';

export type CategoryT<Snapshot extends Campaign> = 
NonNullable<Snapshot['snapshot']['snapshot_views']['vote']['categories']>[0]['vote_category'];
export type NomineeMediaData = NonNullable<(CategoryT<OpenSnapshot> | CategoryT<ClosedSnapshot>)['vote_choices']>[0]

export interface PropertyName {
  name: string;
  reference: string;
  text: string;
}

export const SnapshotType = {
  MULTI_VOTE: 'multiVote',
  SINGLE_VOTE: 'singleVote',
  CLOSED: 'closed'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type SnapshotType = typeof SnapshotType[keyof typeof SnapshotType];

export const ErrorType = {
  GEO: 'geo',
  GENERIC: 'generic',
  VOTE: 'vote',
  OVERLIMIT: 'overlimit',
  WINDOW: 'window',
  APPLE_PRIVATE_RELAY: 'apr',
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ErrorType = typeof ErrorType[keyof typeof ErrorType];

// Snapshots
export type OpenSnapshot = MultiVote | SingleVoteSnapshot;
export type ClosedSnapshot = Closed;
export type Campaign = MultiVote | SingleVoteSnapshot | Closed;
export type MultiVoteSnapshot = MultiVote;
export type SingleVoteSnapshot = SingleVote;
