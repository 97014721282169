import { visit } from 'unist-util-visit';

export function rehypeBrToNewline() {
  const visitor = (node: any) => {
    const breakRegex = new RegExp(/^<br[ ]*\/?>$/);

    if (breakRegex.test(node.value)) {
      node.type = 'element';
      node.tagName = 'br';
      node.children = [];
      node.properties = {};
      delete node.value;
    }
  };

  return (tree: any) => {
    visit(tree, 'raw', visitor);
  };
}
