import { HeroSection, VoteGrid, Countdown } from 'features';
import { InfoContainer } from './InfoContainer';
import { SnapshotProviders } from './SnapshotProviders';

export const ClosedSnapshot = () => {
  return (
   <SnapshotProviders>
      <HeroSection />
      <VoteGrid />
      <InfoContainer>
        <Countdown />
      </InfoContainer>
    </SnapshotProviders>
  )
}