import { Box, Image, Stack } from '@chakra-ui/react';
import { Text } from 'components';

type SponsorProps = {
  text?: string
  image?: string,
  alt?: string,
  direction?: 'row' | 'column'
  colorMode?: 'light' | 'dark'
}

export const SPONSOR_WIDTH = 90;

export const Sponsor = ({ text, image, alt, direction = 'column', colorMode = 'dark' }: SponsorProps) => {

  if (!image) return null;

  return (
    <Stack direction={direction} spacing={2} align="center" justify="center">
      <Text fontSize="xs" fontWeight="800" color={`Sponsor.text.${colorMode}`} whiteSpace="nowrap">{text}</Text>
      <Image src={image} alt={alt} w={`${SPONSOR_WIDTH}px`} />
    </Stack>
  )
}