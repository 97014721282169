import { Container } from '@telescope/cassini-ui';
import { useBreakpointValue } from '@telescope/cassini-hooks';
import { useWidget } from 'providers';
import { Campaign } from 'types';
import { Breakpoints } from 'types';
import { HeroContent } from './HeroContent';
import { HeroImage } from './HeroImage';

export const HeroSection = () => {
  const { data: heroSection } = useWidget({ select: (data: Campaign) => data.snapshot.snapshot_views.heroSection });
  const { image } = heroSection!;

  const breakpoint = useBreakpointValue({ base: 'base', md: 'md', lg: 'lg' }) as Breakpoints;
  const src = image[breakpoint];

  if (src) {
    return (
      <HeroImage src={src}>
        <HeroContent color="HeroSection.imageScheme.text" />
      </HeroImage>
    )
  }

  return (
    <Container bg="HeroSection.textScheme.bg" py={[8, 8, 12]} flexGrow={2}>
      <HeroContent color="HeroSection.textScheme.text" />
    </Container>
  )
}