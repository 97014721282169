export type DisplayProps = { 
  children: React.ReactNode,
   /**
   * Conditional value to determine if children should be hidden
   * Defaults to false
   */
  hide?: boolean
} 

export const Display = ({ children, hide = false }: DisplayProps) => {
  if (hide) return null;

  return (
    <>{children}</>
  )
}

