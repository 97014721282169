import React, { useMemo } from 'react';
import { CassiniProvider, Container, Spinner } from '@telescope/cassini-ui';
import { ErrorBoundary } from 'react-error-boundary';
import { VoteApiProvider } from '@telescope/cassini-hooks';
import { useWidget, TrackingProvider } from 'providers';
import { FallbackError } from 'components';
import { customTheme } from 'theme';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScrollProvider } from 'providers';
import { RULESET_SETTINGS, getAppConfig } from 'utils';
import { Campaign, SnapshotType } from 'types';

type AppProviderProps = {
  children: React.ReactNode;
};

const { basename, isDevMode } = getAppConfig();

export const AppProvider = ({ children }: AppProviderProps) => {
  const { data: ruleset } = useWidget({ select: (data: Campaign) => data.widget.settings.campaignSettings.ruleset });
  const { data: snapshotType } = useWidget({ select: (data: Campaign) => data.snapshot.type });
  const voteApiSettings = useMemo(() => {
    return snapshotType === SnapshotType.SINGLE_VOTE? 
      {apiKey: ruleset?.singleVote.apiKey, versionId: `${ruleset?.singleVote.versionId}${RULESET_SETTINGS.SINGLE_VOTE_VERSION_CHECK}`} :
      {apiKey: ruleset?.multiVote.apiKey, versionId: `${ruleset?.multiVote.versionId}${RULESET_SETTINGS.MULTI_VOTE_VERSION_CHECK}`}
  }, [snapshotType, ruleset]);
  
  return (
    <CassiniProvider theme={customTheme()}>    
      <React.Suspense
        fallback={
          <Container centerContent>
            <Spinner />
          </Container>
        }>
        <ErrorBoundary FallbackComponent={FallbackError}>
          <ScrollProvider>
            <Router basename={basename}>
              <VoteApiProvider
                settings={voteApiSettings}
                options={{
                  devMode: isDevMode,
                  authType: 2
                }}>
                <TrackingProvider>
                {children}
                </TrackingProvider>
              </VoteApiProvider>
            </Router>
          </ScrollProvider>
        </ErrorBoundary>
      </React.Suspense>
    </CassiniProvider>
  )
}