
import { TurboVoteProvider, VoteGridProvider, ModalsProvider } from 'features';

export const SnapshotProviders = ({ children } : { children: React.ReactNode }) => {
  return (
    <TurboVoteProvider>
      <VoteGridProvider>
        <ModalsProvider>
          { children }
        </ModalsProvider>
      </VoteGridProvider>
    </TurboVoteProvider>
  )
}