import { useSession } from '../lib';
import { Button } from '@telescope/cassini-ui';
import { useVotingGrid } from 'features/vote';
import { useWidget, useTracking } from 'providers';
import { OpenSnapshot } from 'types';

export const AuthNav = () => {
  const { session, logout } = useSession();
  const { handleLogout } = useVotingGrid();
  const { data: authLink } = useWidget({ select: (data: OpenSnapshot) =>  data.widget.auth.authLink });
  const { content } = authLink!;

  const { trackClickEvent } = useTracking();

  if (!session?.user.user_id) {
    return null;
  }

  const handleLogoutClick = () => {
    trackClickEvent({ name: 'logout button', url: window.location.href });
    handleLogout();
  }

  return (
    <Button
      variant="link"
      className="AuthNav__login-btn"
      color="Footer.link.hover"
      fontSize="sm"
      px={10}
      onClick={handleLogoutClick}>
      {content.logout}
    </Button>
  )
}