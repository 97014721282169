export const InputName = {
  EMAIL: 'user_email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  DISPLAY_NAME: 'display_name',
  US_PHONE_NUMBER: 'us_phone_number',
  DATE: 'date',
  DOB: 'dob',
  STATE: 'state',
  CODE: 'code',
  SECONDARY_INFO: 'secondary_info',
  CHECKBOX_LEGAL1: 'optin_legal1',
  CHECKBOX_LEGAL2: 'optin_legal2',
  CHECKBOX_OPTIN1: 'optin_optin1',
  CHECKBOX_OPTIN2: 'optin_optin2',
  WRITE_IN: 'write_in'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type InputName = typeof InputName[keyof typeof InputName];

export const InputType = {
  EMAIL: 'email',
  TEXT: 'text',
  PHONE: 'tel',
  SELECT: 'select',
  DATE: 'date',
  CHECKBOX: 'checkbox',
  NUMBER: 'number'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type InputType = typeof InputType[keyof typeof InputType];

export const INPUT_MAP = {
  [InputName.EMAIL]: {
    type: InputType.EMAIL,
    rules: {
      pattern: {
        value: new RegExp(
          /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i
        ),
        message: 'Please enter a valid email.',
      },
    },
    maxLength: 40,
  },
  [InputName.FIRST_NAME]: {
    type: InputType.TEXT,
    maxLength: 320,
  },
  [InputName.LAST_NAME]: {
    type: InputType.TEXT,
    maxLength: 320,
  },
  [InputName.DISPLAY_NAME]: {
    type: InputType.TEXT,
    maxLength: 320,
  },
  [InputName.US_PHONE_NUMBER]: {
    type: InputType.PHONE,
    rules: { 
      pattern: {
        value: new RegExp(/^[1-9]\d{2}[2-9]\d{6}$/),
        message: 'Please enter a valid phone number.'
      } 
    },
  },
  [InputName.STATE]: {
    type: InputType.SELECT,
  },
  [InputName.DATE]: {
    type: InputType.DATE,
    rules: { pattern: new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) },
  },
  [InputName.DOB]: {
    type: InputType.DATE,
    rules: { pattern: new RegExp(/^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/) },
    max: new Date().toISOString().split('T')[0] 
  },
  [InputName.SECONDARY_INFO]: {
    type: InputType.TEXT,
    maxLength: 320,
  },
  [InputName.CHECKBOX_LEGAL1]: {
    type: InputType.CHECKBOX,
  },
  [InputName.CHECKBOX_LEGAL2]: {
    type: InputType.CHECKBOX,
  },
  [InputName.CHECKBOX_OPTIN1]: {
    type: InputType.CHECKBOX,
  },
  [InputName.CHECKBOX_OPTIN2]: {
    type: InputType.CHECKBOX,
  },
  [InputName.WRITE_IN]: {
    type: InputType.NUMBER,
  },
} as const;
// eslint-disable-next-line -- intentionally naming the object the same as the type
export type INPUT_MAP = typeof INPUT_MAP[keyof typeof INPUT_MAP];

// States
export const US_STATES = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'American Samoa', value: 'AS' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'District Of Columbia', value: 'DC' },
  { label: 'Federated States Of Micronesia', value: 'FM' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Guam', value: 'GU' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Marshall Islands', value: 'MH' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Northern Mariana Islands', value: 'MP' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Palau', value: 'PW' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Puerto Rico', value: 'PR' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virgin Islands', value: 'VI' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
] as const;
