import { HeroSection, TurboBanner, VoteGrid, ProgressBar, Countdown, AuthNav } from 'features';
import { InfoContainer } from './InfoContainer';
import { SnapshotProviders } from './SnapshotProviders';

export const OpenSnapshot = () => {
  return (
    <SnapshotProviders>
      <TurboBanner />
      <HeroSection />
      <VoteGrid />
      <ProgressBar />
      <InfoContainer>
        <Countdown />
        <AuthNav />
      </InfoContainer>
    </SnapshotProviders>
  )
}