import ReactMarkdown, { Options as ReactMarkdownOptions } from 'react-markdown';
import { rehypeBrToNewline } from './util';
import { Link } from '@telescope/cassini-ui';
import { Link as RouterLink } from 'react-router-dom';

const blockLevelElements = [
  'aside',
  'blockquote',
  'div',
  'dl',
  'figure',
  'form',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'li',
  'nav',
  'ul',
  'ol',
  'p',
  'section',
  'table',
];

const LinkRenderer = ({
  children,
  ...props
}: React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>) => {
  if (!props.href || !children) return null;

  const isExternal = /https?:\/\//.test(props.href?.toLowerCase() ?? '');

  return (
    <Link to={props.href} as={RouterLink} textDecoration="underline" target={isExternal ? '_blank' : '_self'}>
      {children}
    </Link>
  );
};

export interface MarkdownProps extends Omit<ReactMarkdownOptions, 'disallowedElements'> {}

export const Markdown = (props: MarkdownProps) => {
  return (
    <ReactMarkdown
      rehypePlugins={[rehypeBrToNewline]}
      disallowedElements={blockLevelElements}
      unwrapDisallowed={true}
      components={{ a: LinkRenderer }}
      {...props}
    />
  );
};
