import { createRoot } from 'react-dom/client';
import { App } from 'features';
// Providers
import { QueryClientProvider } from '@tanstack/react-query';
import { PymProvider } from '@telescope/cassini-hooks';
import { AppProvider, StorageProvider } from 'providers';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { WidgetProvider } from 'providers';
import { HelmetProvider } from 'react-helmet-async';
import { getAppConfig } from 'utils';

import { queryClient } from 'lib';

const { appHash } = getAppConfig();

const container = document.getElementById('root');

if (!container) throw new Error ('Root element not found');

if (process.env.REACT_APP_MOCK_REQUESTS === 'true') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

const root = createRoot(container);

root.render(
  <HelmetProvider>
    <PymProvider>
      <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
        <StorageProvider prefix={`${appHash}:`}>
          <WidgetProvider pollingRate={5000}>
            <AppProvider>
              <App />
            </AppProvider>
          </WidgetProvider>
        </StorageProvider>
      </QueryClientProvider>
    </PymProvider>
  </HelmetProvider>);
