export const TWITTER_SHARE_URL = 'https://twitter.com/intent/tweet';

export const AUTH_METHODS = {
  EMAIL: 'email',
  FACEBOOK: 'fb'
};

export const ROUTES = {
  HOME: '/',
  LOGIN: '/auth/login',
  REGISTER: '/auth/register',
  PROFILE: '/auth/profile',
  LEADERBOARD: '/leaderboard',
  ERROR: '/errors'
};

// API
export const ACTION_TYPES = {
  GET: 'get',
  VOTE: 'vote',
  LOGIN: 'login',
  REGISTER: 'register'
};

// FB
export const FB_ERROR_TYPES = {
  GENERIC: 'fbGeneric',
  PERMISSIONS: 'fbPermissions'
};

// Auth Facebook
export const FACEBOOK_AUTH_TYPES = {
  REAUTHENTICATE: 'reauthenticate',
  REAUTHORIZE: 'reauthorize',
  REREQUEST: 'rerequest'
}

// Auth
export const LOCALSTORAGE = {
  AUTH: '_Auth'
}

// Ruleset
export const RULESET_SETTINGS = {
  MULTI_VOTE_VERSION_CHECK: 'JzaDMbCdyKvpB2tc8gWU',
  SINGLE_VOTE_VERSION_CHECK: 'dmSYb9hLgWRfkv65erUJ',
  TURBO_VOTE_VERSION_CHECK: 'qHvAJNhy85XkQLW4MR7t',
}