
export const ProviderId = {
  EMAIL: 'email',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  GUEST: 'guest'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ProviderId = typeof ProviderId[keyof typeof ProviderId];


export const ProviderType = {
  SYNCHRONOUS: 'synchronous',
  OAUTH: 'oauth',
  IDENTITY: 'identity'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type ProviderType = typeof ProviderType[keyof typeof ProviderType];