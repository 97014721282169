import { createContext } from '@chakra-ui/react-context';
import {
  chakra,
  forwardRef,
  HTMLChakraProps,
  omitThemingProps,
  SystemStyleObject,
  ThemingProps,
  useMultiStyleConfig,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/shared-utils';

const [TimeStatStylesProvider, useTimeStatStyles] = createContext<Record<string, SystemStyleObject>>({
  name: `TimeStatStylesContext`,
  errorMessage: `useTimeStatStyles returned is 'undefined'. Seems you forgot to wrap the components in "<TimeStat />" `,
});

export { useTimeStatStyles };

export interface TimeStatProps extends HTMLChakraProps<'div'>, ThemingProps<'TimeStat'> {}

export const TimeStat = forwardRef<TimeStatProps, 'div'>((props, ref) => {
  const styles = useMultiStyleConfig('TimeStat', props);
  const timeStatStyles: SystemStyleObject = {
    position: 'relative',
    flex: '1 1 0%',
    ...styles.root,
  };

  const { className, children, ...rest } = omitThemingProps(props);

  return (
    <TimeStatStylesProvider value={styles}>
      <chakra.div ref={ref} {...rest} className={cx('cassini-time-stat', className)} __css={timeStatStyles}>
        <dl>{children}</dl>
      </chakra.div>
    </TimeStatStylesProvider>
  );
});

TimeStat.displayName = 'TimeStat';
