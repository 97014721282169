import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { VoteAPIResponseCode } from '@telescope/cassini-hooks';

export const AuthType = {
  LOGIN: 'login',
  REGISTER: 'register',
  UPDATE_PROFILE: 'modify_user'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type AuthType = typeof AuthType[keyof typeof AuthType];

type AuthRes = {
  response_code: VoteAPIResponseCode
  [x: string]: any
}

type AuthProps = {
  children: React.ReactNode
  // @TODO: add generics for handler fn
  handler: (...props: any) => any
  // @TODO ts: fix providers type
  // @TODO ts: enforce id to be one of allowed provider types
  providers: any[]
}

type AuthValue = {
  login: <AuthResT = AuthRes>(data: Record<string, any>) => Promise<AuthResT>
  register: <AuthResT = AuthRes>(data: Record<string, any>) => Promise<AuthResT>
  updateProfile: <AuthResT = AuthRes>(data: Record<string, any>) => Promise<AuthResT>
  isUserRegistered: (data: Record<string, any>) => Promise<boolean>
  getProviderById: (id: string) => any
  // @TODO ts: fix providers type
  providers: any[]
}

const AuthContext = createContext<AuthValue | undefined>(undefined);

export const AuthProvider = ({ children, providers, handler }: AuthProps) => {
  const getProviderById = useCallback((id: string) => {
    return providers.find(p => p.id === id)
  }, [providers]);

  const login = async <AuthResT = AuthRes>(data: Record<string, any>): Promise<AuthResT> => {
    const res = await handler({ action_type: AuthType.LOGIN, ...data });

    if (res?.response_code !== VoteAPIResponseCode.VALID) {
      return Promise.reject(res?.response_code);
    }

    return res;
  }

  const register = async <AuthResT = AuthRes>(data: Record<string, any>): Promise<AuthResT> => {
    const res = await handler({ action_type: AuthType.REGISTER, ...data });

    if (res?.response_code !== VoteAPIResponseCode.VALID) {
      return Promise.reject(res?.response_code);
    }

    return res;
  }

  const updateProfile = async <AuthResT = AuthRes>(data: Record<string, any>): Promise<AuthResT> => {
    const res = await handler({ action_type: AuthType.UPDATE_PROFILE, ...data });

    if (res?.response_code !== VoteAPIResponseCode.VALID) {
      return Promise.reject(res?.response_code);
    }

    return res;
  }

  const isUserRegistered = async (data: Record<string, any>): Promise<boolean> => {
    const res = await handler({ action_type: AuthType.LOGIN, ...data });

    if (res?.response_code === VoteAPIResponseCode.GENERAL_INVALID) {
      return false;
    }

    return true;
  }

  const value = useMemo(() => ({
    providers,
    getProviderById,
    login,
    register,
    updateProfile,
    isUserRegistered
  }), [
    providers,
    getProviderById,
    login,
    register,
    updateProfile,
    isUserRegistered
  ])

  return (
    <AuthContext.Provider value={value}>
      { children }
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error('Must be used inside AuthProvider')
  }

  return context;
}



