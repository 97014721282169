import { StyleFunctionProps } from '@telescope/cassini-ui';

export const checkboxTheme = {
  baseStyle: (props: StyleFunctionProps) => {
    return {
      control: {
        _invalid: {
          borderColor: 'Form.error'
        },
      },
    }
  }
};