import React, { createContext, useContext, useMemo } from 'react';
import { getAppConfig } from 'utils';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { axiosETAGCache } from 'axios-etag-cache';
import axios from 'axios';

const axiosWithETAGCache = axiosETAGCache(axios);

/**
 * Hook for polling widgetstate. 
 * 
 * Will poll once immediately, and then on the specified polling frequency (default = 5 seconds).
 * 
 * This uses React Query's useQuery. Inherits all its options and returns:
 * https://react-query.tanstack.com/reference/useQuery
 * Use the QueryClientProvider component to connect and provide a QueryClient to the application:
 * https://react-query.tanstack.com/reference/QueryClientProvider
 * 
 * useWidget must be wrapped in a WidgetProvider.
 * @param {number} pollingRate - Specifies polling frequency

 * WidgetProvider optional params will override values set from appConfig helper functions: 
 * @param {string} apiUrl - Overrides api url 
 * @param {boolean} includeServerTimestamp - If true, will include the server time read from the response header. This will cause the data object to change on every poll, so should be used sparingly.

 * @example
 * // Wrap App with WidgetProvider
 * // Basic Usage (polls every 5 seconds)
 * <WidgetProvider pollingRate={5000}> 
 *  <App /> 
 * </WidgetProvider>
 * // Get data using useWidget hook:
 * const { data } = useWidget();
 * 
 * // Polls a widget using specific apiUrl and widgetHash
 * <WidgetProvider pollingRate={5001} apiUrl="https://widgetstate-test.votenow.tv/v3/state/"> 
 *  <App /> 
 * </WidgetProvider>
 *
 * If no options are set, hook will use appConfig helpers to determine baseUrl
 * baseUrl: to use enviroment based baseUrl, a window tsReactInitConfig variable is required:
 * window.tsReactInitConfig = {
    widgetStateUrl: 'https://widgetstate-<env>.votenow.tv/v3/state/',
    connectUrl: 'https://voteapi-<env>.votenow.tv/s2/vote',
    previewUrl: 'https://ssp-api-<env>.votenow.tv/v1/widget_previews/'
  };
* @returns CMSData
  { snapshot: {...}, widget: {...} }
*/

type WidgetStateOptions = {
  pollingRate?: number;
  children: React.ReactNode;
  apiUrl?: string;
  includeServerTimestamp?: boolean;
};

export type UseWidgetResponseDTO = {
  snapshot: Record<string, any>;
  widget: Record<string, any>;
  sid: number;
  nextSnapshotId: number | null;
  nextSchedule: number | null;
  /**
   * The current timestamp from the widgetstate response. Note that this value will not update on every poll if the widgetstate response has not changed.
   */
  currentTimestamp: number | null;
  /**
   * The server timestamp from the widgetstate response header. Note that this value will update on every poll if includeServerTimestamp is set to true.
   */
  serverTimestamp?: string;
};

type WidgetContextType = {
  baseURL: string;
  hash: string;
  includeServerTimestamp?: boolean;
  pollingRate: number | null;
};

const MIN_POLLING_RATE = 5000;

export const WidgetContext = createContext<WidgetContextType | undefined>(undefined);

export const WidgetProvider = ({
  children,
  apiUrl,
  pollingRate: pollingInterval = 0,
  includeServerTimestamp = false,
}: WidgetStateOptions) => {
  const { appHash: hash, widgetUrl, widgetStatePresets } = getAppConfig();

  const baseURL = apiUrl ?? widgetUrl;
  const pollingRate = Math.max(MIN_POLLING_RATE, pollingInterval);

  const value = useMemo(
    () => ({
      baseURL,
      hash,
      includeServerTimestamp,
      pollingRate,
      ...(widgetStatePresets && widgetStatePresets),
    }),
    [baseURL, hash, pollingRate, includeServerTimestamp, widgetStatePresets],
  );

  return <WidgetContext.Provider value={value}>{children}</WidgetContext.Provider>;
};

export type UseWidgetOptions<Widget, WidgetQuery> = UseQueryOptions<Widget, Error, WidgetQuery>;

export const useWidget = <Widget, WidgetQuery = any>(options?: UseWidgetOptions<Widget, WidgetQuery>) => {
  const context = useContext(WidgetContext);

  if (!context) {
    throw new Error(`useWidget must be used within an WidgetProvider`);
  }

  const { hash, baseURL, includeServerTimestamp, pollingRate } = context;

  const getData = async (): Promise<Widget> => {
    const { data, headers } = await axiosWithETAGCache.get(hash, { baseURL });

    if (!data.data) {
      throw new Error('Invalid Widget Id');
    }
    const widgetData = {
      name: data.name,
      snapshot: data.snapshot?.data,
      widget: data.data,
      sid: data.snapshot?.snapshot_id,
      nextSnapshotId: data.next_snapshot_id,
      nextSchedule: data.next_schedule,
      currentTimestamp: data.current_timestamp,
      ...(includeServerTimestamp && { serverTimestamp: headers.date }),
    } as Widget;

    return widgetData;
  };

  const queryKey = ['widget', hash];

  const queryOptions = {
    queryKey,
    queryFn: getData,
    enabled: !!hash,
    staleTime: Infinity,
    ...options,
    refetchInterval: pollingRate ?? Infinity,
  };

  return useQuery(queryOptions);
};