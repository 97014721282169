import { CategoryButton } from './CategoryButton';
import { AccordionItem, AccordionButton, AccordionPanel } from '@telescope/cassini-ui';
import { ACCORDION_MOTION_SETTINGS } from 'features/vote/utils/constants';
import { useNavigate } from 'react-router-dom';
import { ROUTES, lazyImport } from 'utils';
import { useTracking } from 'providers';

const { CategoryGrid } = lazyImport(() => import('features'), 'CategoryGrid');

export const Category = ({ slug } : { slug: string }) => {

  const navigate = useNavigate();
  const { trackClickEvent } = useTracking();

  const handleToggleClick = (isExpanded: boolean) => {
    isExpanded && trackClickEvent({ name: `open category_${slug}` });
    return isExpanded? navigate(`/${slug}`) : navigate(ROUTES.HOME);
  }

  return (
    <AccordionItem borderWidth={0} id={slug}>
      {({ isExpanded }) => (
      <>
      <AccordionButton p={0} _hover={{bg: 'transparent'}} onClick={() => handleToggleClick(!isExpanded)}>
        <CategoryButton isExpanded={isExpanded} categorySlug={slug} />
      </AccordionButton>

      <AccordionPanel 
        motionProps={{
          transition: {
            enter: {delay: 0, duration: ACCORDION_MOTION_SETTINGS.DURATION}, 
            exit: {delay: 0}
        }}} 
        p={0}>
        <CategoryGrid categorySlug={slug} />
      </AccordionPanel>
      </>
    )}
    </AccordionItem>
  )
}