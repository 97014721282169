import { Box, Button, Container, Heading, Text } from '@telescope/cassini-ui';
import { FallbackProps } from 'react-error-boundary';

export const FallbackError = ({ error, resetErrorBoundary }: FallbackProps) => {

  return (
    <Box mx={10}>
      <Container centerContent my={10} py={10} bg="surface" shadow="dark-lg" rounded="md" maxW="lg" color="on-surface">
        <Heading as="h1">Oops</Heading>
        <Text mb={6}>Something went wrong</Text>
        <Button onClick={() => window.location.assign(window.location.href)}>Try Again</Button>
      </Container>
    </Box>
  );
};
