import { SnapshotType } from 'types'

const CLICK = {
  CLICK_LINK_TYPE: 'o',
  CLICK_ACTIVITY: 'actionCall',
  CLICK_NO_DEST: 'no destination',
}

const PAGE = {
  PAGE_NAME: 'cmt|events|voting|2024-cmt-music-awards',
  PAGE_CHANNEL: 'events',
  PAGE_EVENT: '2024 CMT Music Awards',
  PAGE_FRANCHISE: '2024 CMT Music Awards',
  PAGE_SERIES: 'no-series',
  PAGE_TYPE: 'voting',
  PAGE_ACTIVITY: 'pageview',
  PAGE_VID_FRANCHISE: 'no-franchise',
  PREV_PAGE_NAME: 'n/a',
}

const CATEGORY = {
  MOD_NAME_MULTI: 'voting_items',
  MOD_NAME_SINGLE: 'voting',
  SOC_METHOD: 'instagram',
}

const CONFIG = {
  WEB_VERSION: 'WebPlex 3.1.1',
  APP_NAME: 'CMT web',
  BRAND_ID: 'cmt',
  LANGUAGE: 'en-us',
  COUNTRY_NAME: 'us',
  VENDOR: 'Telescope',
}

export const RETRY_SETTINGS = {
  MAX_ATTEMPTS: 10,
  RETRY_DELAY: 800
}

export type OmnitureClickEventParams = {
  name: string
  destination?: string
  modname?: string
  section?: string
  url?: string
  snapshotType?: SnapshotType
}

export const omnitureClickEvent = ({name, destination, modname, section, url, snapshotType}: OmnitureClickEventParams) => {
  const eventUrl = url || window.location.href;

  const contextVars = {
    'pageName': section ? `${PAGE.PAGE_NAME}|${section}` : PAGE.PAGE_NAME,
    'v.destination': destination ? destination : CLICK.CLICK_NO_DEST,
    'v.actName': `clicks on ${name}`,
    'v.pageType': PAGE.PAGE_TYPE,
    'v.pageURL': eventUrl,
    'v.pageFranchise': PAGE.PAGE_FRANCHISE,
    'v.appName': CONFIG.APP_NAME,
    'v.vendor': CONFIG.VENDOR,
    'v.brandID': CONFIG.BRAND_ID,
    'v.activity': CLICK.CLICK_ACTIVITY,
    'v.pv': 'false',
    'linkType': CLICK.CLICK_LINK_TYPE,
    'v.channel': PAGE.PAGE_CHANNEL,
    'v.pageEvent': PAGE.PAGE_EVENT,
    'v.language': CONFIG.LANGUAGE,
    'v.countryName': CONFIG.COUNTRY_NAME,
    'v.actPageName': eventUrl,
    'v.modname': snapshotType === SnapshotType.SINGLE_VOTE? 
      CATEGORY.MOD_NAME_SINGLE: (modname || CATEGORY.MOD_NAME_MULTI)
  };

  window.Bento?.onPageEvent(contextVars);
}

export type OmniturePageEventParams = {
  name?: string
  url?: string
}
export const omniturePageEvent = ({name, url}: OmniturePageEventParams) => {
  const pageName = `${PAGE.PAGE_NAME}${name? `|${name}`: ''}`;
  const eventUrl = url || window.location.href;
  
  const contextVars = {
    'pageName': pageName,
    'pageURL' : eventUrl,
    'v.channel': PAGE.PAGE_CHANNEL,
    'v.pageType': PAGE.PAGE_TYPE,
    'v.pageFranchise': PAGE.PAGE_FRANCHISE,
    'v.pageSeries': PAGE.PAGE_SERIES,
    'v.pageURL': eventUrl,
    'v.pageEvent': PAGE.PAGE_EVENT,
    'v.webVersion': CONFIG.WEB_VERSION,
    'v.brandID': CONFIG.BRAND_ID,
    'v.language': CONFIG.LANGUAGE,
    'v.countryName': CONFIG.COUNTRY_NAME,
    'v.vendor': CONFIG.VENDOR,
    'v.activity': PAGE.PAGE_ACTIVITY,
    'v.pv': 'true',
    'v.appName': CONFIG.APP_NAME,
    'v.vidFranchise': PAGE.PAGE_VID_FRANCHISE,
    'v.modname': null,
    'v.actName': null,
    'v.actPageName': null,
    'v.destination': null
  };

  window.Bento?.onNewPage(contextVars);
}