export interface CategoryState {
  /**
   * Keeps track of total votes temporarily allocated per category / nominee
   */
  [category: string]: {
    allocated: {
      [propertyName: string]: number,
    }
    total: number,
    isSubmitted?: boolean
  }
}

export const initialState = {};

export const VoteAction = {
  ADD_VOTE: 'ADD_VOTE',
  SUBTRACT_VOTE: 'SUBTRACT_VOTE',
  SET_VOTES: 'SET_VOTES',
  RESET: 'RESET'
} as const;

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type VoteAction = typeof VoteAction[keyof typeof VoteAction];

type AddVoteAction = {
  type: typeof VoteAction.ADD_VOTE;
  payload: {
    categoryId: string,
    nomineeId: string
  };
};

type SubtractVoteAction = {
  type: typeof VoteAction.SUBTRACT_VOTE;
  payload: {
    categoryId: string,
    nomineeId: string
  };
};

type SetVoteAction = {
  type: typeof VoteAction.SET_VOTES;
  payload: CategoryState
};

type ResetVoteAction = {
  type: typeof VoteAction.RESET;
};

export type VoteTypes = AddVoteAction['type'] | SubtractVoteAction['type']
export type CategoryActionTypes = AddVoteAction | SubtractVoteAction | SetVoteAction | ResetVoteAction;

export const VoteReducer = (state: CategoryState, action: CategoryActionTypes): CategoryState => {
  switch (action.type) {
    case VoteAction.ADD_VOTE: {
      const { categoryId, nomineeId } = action.payload;
      const categoryVotes = state[categoryId] || { allocated: {}, total: 0 };
      const prevAllocatedVotes = categoryVotes.allocated[nomineeId] || 0;
    
      return { 
        ...state, 
        [categoryId]: {
          allocated: {
            ...categoryVotes.allocated,
            [nomineeId]: prevAllocatedVotes + 1,
          },
          total: categoryVotes.total + 1
        }
      }
    }
    case VoteAction.SUBTRACT_VOTE: {
      const { categoryId, nomineeId } = action.payload;
      const categoryVotes = state[categoryId] || { allocated: {}, total: 0 };
      const prevAllocatedVotes = categoryVotes.allocated[nomineeId] || 0;

      const currentAllocatedVotes = Math.max(0, prevAllocatedVotes - 1);

      const { [nomineeId]: nominee, ...rest } = categoryVotes.allocated;

      return { 
        ...state, 
        [categoryId]: {
          allocated: {
            ...rest,
            ...(currentAllocatedVotes > 0 && { [nomineeId]: currentAllocatedVotes }), // remove nominee if votes are 0
          },
          total: Math.max(0, categoryVotes.total - 1)
        }
      }
    }
    case VoteAction.SET_VOTES: {
      return { 
        ...state, 
        ...action.payload
      }
    }
    case VoteAction.RESET: 
      return initialState
    default:
      return state;
  }
}