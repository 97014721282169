import { 
  Modal, ModalProps, ModalOverlay, ModalContent, ModalBody,
  IconButton, Link, AspectRatio, Button, Box, VStack
} from '@telescope/cassini-ui';
import { useMtvData } from 'hooks';
import { CloseRound, Heading, Text, Image } from 'components';
import parse from 'html-react-parser';
import { keyBy } from 'lodash-es';

type UpsellModalProps = Omit<ModalProps, "children">;

export const UpsellModal = (props: UpsellModalProps) => {
  const data = useMtvData('MTVUpsellModal', 'MTVUpsellModalLoaded');

  if (!data) return null;

  const images = keyBy(data.images, (image) => image.imageUsageType);

  // Use > *:not(style) to overwrite Chakra's AspectRatio children styles
  return (
    <Modal {...props}>
      <ModalOverlay bg="rgba(0,0,0,.8)" />
      <ModalContent w="91vw" maxW="640px">
        <ModalBody p={0} bg="UpsellModal.bg" color="UpsellModal.text" fontFamily="UpsellModal.text">
          <AspectRatio ratio={{base: 1, sm: 16/9 }} 
            bgImage={images.background?.url}
            bgSize="cover" bgPosition="center"
            sx={{'> *:not(style)': {justifyContent: 'flex-end'}}}>
            <VStack bgGradient='linear(transparent, black)' 
                    textAlign="center" spacing={{base: 2, sm: 5}}
                    px={{base: 8, sm: 12}} pb={{base: 9, sm: 12}}>
              <Image src={images.show_logo?.url} alt="" h={{base: 8, sm: 10}} useWebp />
              <Heading fontSize={{base: '2xl', md: '3xl'}} lineHeight={1.1}>{data.title}</Heading>
              <Text fontSize="md">{data.subheaderText}</Text>
            </VStack>
          </AspectRatio>

          <VStack px={{base: 4, sm: 20}} pb={{base: 8, sm: 14}} pt={{base: 8, sm: 10}} spacing={6}>
            <Box sx={{ ul: {pl: 6}, li: {pl: 5, mb: 4} }}>
              {parse(data.copy)}
            </Box>

            <Button as={Link} href={data.promoResourceLinks[0].url} fontSize={{base: 'xs', md: 'sm'}} w="full">
              {data.promoResourceLinks[0].copy}
            </Button>
          </VStack>

        </ModalBody>
        <IconButton icon={<CloseRound />} aria-label='Close' onClick={props.onClose} 
            isRound={true} bg="transparent" fill="white" _hover={{ bg: 'transparent' }}
            position="absolute" top={2} right={2} p={0} fontSize="24px"
          />
      </ModalContent>
    </Modal>
  )
}