import { Image } from '@telescope/cassini-ui';
import { ImageProps } from '@chakra-ui/react';
import { useMemo } from 'react';

export const WebpImage = ({ src, ...props }: ImageProps) => { 
  const webpSrc = useMemo(() => {
    if (!src) return '';
    let url = new URL(src);
    url.searchParams.append('format', 'webp');
    return url.toString();
  }, [src]);

  return (
    <picture>
      <source srcSet={webpSrc} type="image/webp" />
      <Image src={src} {...props} />
    </picture>
  )
}