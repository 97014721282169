import { matchPath } from 'react-router-dom';

/**
 * @param {number} apiHash - hardcoded apiHash. allows getAppConfig to use correct url pattern for each variable it returns
 * Returns CMSify application's config values
 * @return isPreviewUrl (boolean).
 * If app is a preview url
 * @return {string} basenamePattern
 * The URL basename pattern. Example '/:widgetId/:previewType/:previewHash'
 * @return {string} basename
 * The URL basename. Example OGzLaxr/styles-preview/ZLncQt0FInmmVPFCjNlU
 * @return {object} urlParams
 * Object containing URL params:
 * { widgetId: OGzRaxr, previewType: 'styles-preview', previewHash: ZLncQt0FInmmVPFCjNlU }
 * @return {string} appHash
 * The widget id on a regular application or the preview hash on application preview. To be used on widgetstate endpoint
 * @return {string} widgetUrl
 * Widgetstate endpoint
 * @return {string} widgetId
 * The application's widgetId parameter
 * @return {boolean} isDevMode
 * If application is in dev mode.
 * @return {string} connectUrl
 * The ConnectURL endpoint
 */

export const PreviewType = {
  TESTING: 'testing-preview',
  STYLES: 'styles-preview',
  LIVE: 'live_preview',
};

// eslint-disable-next-line -- intentionally naming the object the same as the type
export type PreviewType = (typeof PreviewType)[keyof typeof PreviewType] & null;

type Params = {
  widgetId: string;
  previewType?: PreviewType;
  previewHash?: string;
  param?: string;
};

export const DEFAULT_ENDPOINTS = {
  CMS: 'https://widgetstate-dev.votenow.tv/v3/state/',
  CONNECT: 'https://voteapi-dev.votenow.tv/s2/vote',
  PREVIEW: 'https://ssp-api-dev.votenow.tv/v1/widget_previews/',
};

export type WidgetStatePresets = {
  pollingRate: number | null;
};

export const WIDGETSTATE_PRESETS: Record<string, WidgetStatePresets> = {
  [PreviewType.TESTING]: {
    pollingRate: null,
  },
  [PreviewType.STYLES]: {
    pollingRate: null,
  },
  [PreviewType.LIVE]: {
    pollingRate: 1000,
  },
};

export const getAppConfig = () => {
  // tsReactInitConfig is set in index.html when we do npm run build . Default values are set here for local webpack devserver testing.
  const tsReactInitConfig = (window as any).tsReactInitConfig || {};

  const {
    widgetStateUrl = DEFAULT_ENDPOINTS.CMS,
    connectUrl = DEFAULT_ENDPOINTS.CONNECT,
    previewUrl = DEFAULT_ENDPOINTS.PREVIEW,
    apiHash = undefined,
    basename: tsBasename = '/',
  } = tsReactInitConfig;

  const getPreviewType = () => {
    const pattern = `${tsBasename}${apiHash ? ':param/' : ':widgetId/:param/'}`;
    const match = matchPath({ path: pattern, end: false }, window.location.pathname);
    const param = (match?.params as Params)?.param;

    if (!param || !Object.values(PreviewType).includes(param)) {
      return null;
    }

    if (param === PreviewType.STYLES) {
      const searchParams = new URLSearchParams(window.location.search);
      const isLivePreview = searchParams.get('mode') === PreviewType.LIVE;
      if (isLivePreview) return PreviewType.LIVE;
    }

    return param;
  };

  const previewType = getPreviewType();
  const isPreviewUrl = !!previewType;

  const getBasenamePattern = (): string => {
    const BASE_URLS = {
      DEFAULT: '/',
      PREVIEW: '/:previewType/:previewHash',
    };

    const basePattern = `${tsBasename}/${(isPreviewUrl ? BASE_URLS.PREVIEW : BASE_URLS.DEFAULT)}`;
    return `${(apiHash ? basePattern : `/:widgetId${basePattern}`)}`.replace(/\/{2,}/g, '/');
  };

  const basenamePattern = getBasenamePattern();
  const match = matchPath({ path: basenamePattern, end: false }, window.location.pathname);
  const urlParams: Record<string, any> | undefined | null = match?.params as Params;
  const basename = urlParams
    ? Object.entries(urlParams).reduce(
        (acc: string, [key, value]): string => acc.replace(`:${key}`, value),
        basenamePattern,
      )
    : '/';

  const getAppHash = () => {
    if (isPreviewUrl && urlParams?.previewHash) return urlParams.previewHash;
    return apiHash || urlParams?.widgetId || '';
  };

  // CMSify
  const appHash: string = getAppHash();

  const widgetUrl: string = isPreviewUrl ? previewUrl : widgetStateUrl;
  const widgetId: string = urlParams?.widgetId || apiHash;
  const widgetStatePresets: WidgetStatePresets | null = previewType ? WIDGETSTATE_PRESETS[previewType] : null;

  // Connect
  const isDevMode: boolean = !!previewType && previewType !== PreviewType.TESTING;

  return {
    isPreviewUrl,
    basenamePattern,
    basename,
    urlParams,
    appHash,
    widgetUrl,
    widgetStatePresets,
    widgetId,
    isDevMode,
    connectUrl,
  };
};
