import { VoteWildcard } from 'features';
import strSlugify from 'slugify';

export const replaceWildcardsWithProgress = (total: number, limit: number, copy?: string) => {
  if (!copy) return '';

  const stringMap = {
    [VoteWildcard.REMAINING]: Math.max(0, limit - total),
    [VoteWildcard.VOTE_LIMIT]: limit,
    [VoteWildcard.VOTES]: total
 };

  return copy.replace(
    new RegExp(Object.keys(stringMap).join("|"),"gi"), 
    (matched) => {
      return `${stringMap[matched as VoteWildcard]}`
    })
};

export const delay = (milliseconds: number) => new Promise(resolve => setTimeout(resolve, milliseconds));

export const slugify = (str: string) => {
  return strSlugify(str, {
    lower: true,
    strict: true,
  })
}