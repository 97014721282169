import { useEffect } from 'react';
import { useWidget } from 'providers';
import { Flex } from '@telescope/cassini-ui';
import { loadFonts } from 'utils';
import { GeoBlocking, Header, Footer, Upsell, BidBarrelAd, initializeBidBarrel } from 'features';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Campaign } from 'types';
import { Display, FallbackError } from 'components';
import { fonts } from 'theme';
import { isMobile } from 'react-device-detect';

export const AppShell = () => {
  const { data: widgetData } = useWidget({ select: (data: Campaign) => data.widget });
  const { global_views: { sitewide } } = widgetData!;

  useEffect(() => {
    loadFonts(fonts);
    initializeBidBarrel();
  }, []);

  return (
    <Flex direction="column" minH='100vh' alignItems="center" {...sitewide.styles.app}>
        <Display hide={isMobile}>
          <BidBarrelAd adId="skybox_leader_plus_sticky"/>
        </Display>
        <Display hide={!isMobile}>
          <BidBarrelAd adId="mobile_skybox_plus_sticky"/>
        </Display>

        <Upsell />
        <Header />

        <Flex as="main" flex={1} flexGrow="2" w="100%" maxW="100%" overflow="hidden" justifyContent="flex-start" direction="column">
          <ErrorBoundary FallbackComponent={FallbackError}>
            <GeoBlocking>
              <Outlet />
            </GeoBlocking>
          </ErrorBoundary>
        </Flex>

        <Footer />
    </Flex>
  )
}